import React, { useState, useEffect, MouseEvent } from 'react';
const { default: axios } = require('axios');
import { io } from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { parseJWT } from '../../../utils';
// Define a type for the current tag
interface Tag {
    text: string;
    color: string;
}

// Define the available tags and their corresponding colors
const tags: Record<string, string> = {
    'New': 'bg-blue-500',
    'Evaluating': 'bg-yellow-500',
    'Evaluate': 'bg-yellow-500',
    'In Progress': 'bg-orange-500',
    'Not Relevant': 'bg-gray-500',
    'Submitted': 'bg-purple-500',
    'Lost': 'bg-red-500',
    'Awarded': 'bg-green-500',
};

// Define the SVG icons
const icons: Record<string, string> = {
    'New': '<svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M14 10l-2 2-2-2m2 2V6"></path></svg>',
    'Evaluating': '<svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
    'Evaluate': '<svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
    'In Progress': '<svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 19l9-7-9-7-9 7 9 7z"></path><path d="M12 12l9-7-9-7-9 7 9 7z"></path><line x1="12" y1="12" x2="12" y2="19"></line></svg>',
    'Not Relevant': '<svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
    'Submitted': '<svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>',
    'Lost': '<svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>',
    'Awarded': '<svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12l5 5l10-10"></path></svg>'
};
interface StatusTagsDropdownProps {
    Statue: any
    tender_id: any
    setTenderList: any
    handleSelectedFolder: any
    setTabName: any
    title: any
    handleSelectedTab: any
    setMovedState: any
    setOpenedStatus: any
}
const socketId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

const Socket = io(`${process.env.REACT_APP_API_URL}`, {
    query: { socketId: socketId }
});
const StatusTagsDropdown: React.FC<StatusTagsDropdownProps> = ({ Statue, tender_id, setTenderList, handleSelectedFolder, setTabName, title, handleSelectedTab, setMovedState, setOpenedStatus}) => {
    // State to manage the current tag
    const [currentTag, setCurrentTag] = useState<Tag>({ text: Statue, color: tags[Statue] });
    // State to manage the visibility of the dropdown
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const { t } = useTranslation();
    useEffect(() => {
        Socket.on('tenderStatusChanged', (message: { status: any, tender_id: any }) => {
            if (tender_id == message.tender_id) {
                changeTag(message.status, tags[message.status]);
            }
        });
        const handleClickOutside = (event: MouseEvent) => {
            if (!(event.target as HTMLElement).closest('#dropdown')) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside as unknown as EventListener);
        return () => {
            document.removeEventListener('click', handleClickOutside as unknown as EventListener);
        };
    }, [tender_id]);
    useEffect(() => {
        setCurrentTag({ text: Statue, color: tags[Statue] })
    }, [Statue])
    // Toggle the dropdown visibility
    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };
    let user = parseJWT(localStorage.getItem("tender_auth"));
    // Change the current tag
    const changeTag = async (text: string, color: string) => {
        const formData: any = new FormData();
        var selTab = "tab_1"
        if (text == "Lost" || text == "Awarded") {
            selTab = "tab_2"
        }
        formData.append('status', text)
        formData.append('tender_id', tender_id)
        formData.append('tab', selTab)
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/setTenderStatus", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200) {
            setMovedState(true)
            setTabName(selTab)
            const data = new FormData();
            data.append("companyId", user.sub.companyId);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getTenders", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            let newIndex =  result.data.data.length
            for(let i = 0; i < result.data.data.length; i++){
                if(result.data.data[i].id == tender_id){
                    newIndex = i+1
                }
            }
            setTenderList(result.data.data);
            setOpenedStatus((prevTabs) => {
                return prevTabs.map((tab) => {
                    if (tab.tabName === selTab) {
                        return { ...tab, isOpened: true, openedIndex: newIndex > 0 ? newIndex - 1 : 0 };
                    } else {
                        return tab;
                    }
                });
            });
            handleSelectedFolder(tender_id, title)
            setTimeout(() => {
                setMovedState(false)
            }, 1000);
        }
        setCurrentTag({ text, color });
        setDropdownVisible(false);
    };

    return (
        <>
            {title!="Company Documents" && (
                <div className="relative inline-block mt-2 ml-2 text-black" id="dropdown">
                    <span
                        className={`inline-flex items-center px-3 py-1.5 mx-1 text-white rounded-full cursor-pointer ${currentTag.color}`}
                        id="current-tag"
                        onClick={toggleDropdown}
                        dangerouslySetInnerHTML={{
                            __html: `${icons[currentTag.text]}${currentTag.text != "New" ? t(currentTag.text) : t("NewTender")}<svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>`
                        }}
                    ></span>
                    {dropdownVisible && (
                        <ul className="absolute left-0 z-10 w-40 mt-2 overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg">
                            {Object.entries(tags).map(([status, color]) =>
                                status !== currentTag.text && status != "New" && status != "Evaluating" && status != "In Progress" ? (
                                    <li key={status}>
                                        <span
                                            className="flex items-center px-3 py-1.5 cursor-pointer hover:bg-gray-100"
                                            onClick={() => changeTag(status, color)}
                                            dangerouslySetInnerHTML={{ __html: `${icons[status]}${t(status)}` }}
                                        ></span>
                                    </li>
                                ) : null
                            )}
                        </ul>
                    )}
                </div>
            )}
        </>
    );
};

export default StatusTagsDropdown;
