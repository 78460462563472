import { useCallback } from 'react';

export const useFetchMentions = (tenderList: any[]) => {
    return useCallback((query: string) => {
        if (!query) return [];

        const lowerQuery = query.toLowerCase();

        // Aggregate documents based on tenderList with tender_id
        const documents = tenderList.flatMap(tender => {
            if (tender.tab === "tab_1") {
                return tender.documents.map(doc => ({
                    id: doc,
                    display: `@${doc}`,
                    type: 'doc',
                    tender_id: tender.id
                }));
            } else if (tender.tab === "tab_2") {
                return tender.documents.map(doc => ({
                    id: doc,
                    display: `@${doc}`,
                    type: 'doc',
                    tender_id: tender.id
                }));
            }
            return [];
        });

        // Add folders from tenderList
        const folders = tenderList.flatMap(tender => {
            if (tender.title === "Company Documents") {
                // Return both English and Norwegian versions for Company Documents
                return [
                    {
                        id: tender.id,
                        display: `@${tender.title}`,
                        type: 'folder',
                        documents: tender.documents,
                        tab: tender.tab
                    },
                    {
                        id: tender.id,
                        display: `@Bedriftsdokumenter`,
                        type: 'folder',
                        documents: tender.documents,
                        tab: tender.tab
                    }
                ];
            }
            // Return single entry for other folders
            return [{
                id: tender.id,
                display: `@${tender.title}`,
                type: 'folder',
                documents: tender.documents,
                tab: tender.tab
            }];
        });


        const allMentions = [...documents, ...folders];
        return allMentions.filter(item => item.display.toLowerCase().includes(`@${lowerQuery}`));
    }, [tenderList]);
};


export const formatMentionText = (text: string) => {
    return text.replace(/@\[@([^\]]+)\]\([^\)]+\)/g, (match, name) => {
        const displayName = name.replace(/\.[^/.]+$/, "");
        return `<span class="mention-tag">@${displayName}</span>`;
    });
};

export function formatNewValue(newValue: string): string {
    // Use a regular expression to remove the @[] syntax and the @ symbol
    return newValue.replace(/@\[(.*?)\]\((.*?)\)/g, '$1').replace(/@/g, '');
}

export const extractDocumentNames = (text: string): string[] => {
    const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const matches: string[] = [];
    let match: RegExpExecArray | null;

    while ((match = regex.exec(text)) !== null) {
        if (match[2]) {
            matches.push(match[2]); // Safely access the second capturing group
        }
    }

    return matches;
};
