import React, { useState, useRef } from 'react';
import { ReactComponent as DotsIcon } from "../../assets/svg/threedots.svg";
import { ReactComponent as InsightRefreshIcon } from "../../assets/svg/insight_refresh.svg";
import { LoadingBar } from '../LoadingBar';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { parseJWT } from '../../utils';
import { ReactComponent as CheckedIcon } from "../../assets/svg/checked.svg";
import { ReactComponent as UncheckedIcon } from "../../assets/svg/unchecked.svg";
import { extractDocumentNames } from '../../utils/mentions';

export interface Prompt {
  id: string;
  title: string;
  text: string;
  type: string;
  isGlobalInsight: string;
  tender_id: string;
  response?: {
    [key: string]: string;
  }[];
  deleted_folder_ids?: string[];
  isLoading?: boolean;
  document?: string;
}

interface PromptCardProps {
  prompt: Prompt;
  index: number;
  selectedFolderID: string;
  companyKnowledgeBaseId: string;
  selectedDocumentList: any[];
  membership?: string;
  widthClass?: string;
  onUpdate?: () => void;
  onEdit?: (prompt: Prompt) => void;
  handleDocumentClick?: (document: string) => void;
  tenderList: any[];
  setPromptList: React.Dispatch<React.SetStateAction<Prompt[]>>;
  promptList: Prompt[];
}

export const PromptCard: React.FC<PromptCardProps> = ({
  prompt,
  index,
  selectedFolderID,
  companyKnowledgeBaseId,
  selectedDocumentList = [],
  membership,
  widthClass = 'w-[calc(50%-16px)]',
  onUpdate,
  onEdit,
  handleDocumentClick,
  tenderList,
  setPromptList,
  promptList
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isGlobalDelete, setIsGlobalDelete] = useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState<number | null>(null);
  const user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;

  const getPromptAnswer = async () => {
    if (!user) return;

    try {
      // Extract and process documents
      const documentsArray: string[] = extractDocumentNames(prompt.text);
      const newDocumentsByTab: any = {
        tab_1: [],
        tab_2: []
      };

      documentsArray.forEach(doc => {
        const tender = tenderList.find(t => t.documents.includes(doc));
        if (tender) {
          const documentObject = { document: doc, tenderId: tender.id };
          if (tender.tab === 'tab_1') {
            newDocumentsByTab.tab_1.push(documentObject);
          } else if (tender.tab === 'tab_2') {
            newDocumentsByTab.tab_2.push(documentObject);
          }
        }
      });

      const selectedDocumentListParam = [
        ...newDocumentsByTab.tab_1.length > 0
          ? newDocumentsByTab.tab_1.map(item => item.document)
          : selectedDocumentList.map(item => item.document),
      ];

      // Set parameters
      let tender_id = selectedFolderID;
      let selectedFolderIDParam = selectedFolderID;
      let companyKnowledgeBaseIdParam = companyKnowledgeBaseId;

      if (newDocumentsByTab.tab_1.length > 0) {
        tender_id = newDocumentsByTab.tab_1[0].tenderId;
        selectedFolderIDParam = newDocumentsByTab.tab_1[0].tenderId;
      }

      if (newDocumentsByTab.tab_2.length > 0) {
        companyKnowledgeBaseIdParam = newDocumentsByTab.tab_2[0].tenderId;
      }

      setPromptList(prevList => {
        const updatedList = [...prevList];
        const promptToUpdate = updatedList.find(p => p.id === prompt.id);
        if (promptToUpdate) {
          promptToUpdate.isLoading = true;
          // Clear existing response for this folder
          if (Array.isArray(promptToUpdate.response)) {
            const folderIndex = promptToUpdate.response.findIndex(
              item => Object.keys(item)[0] === selectedFolderID
            );
            if (folderIndex !== -1) {
              promptToUpdate.response[folderIndex] = { [selectedFolderID]: '' };
            }
          }
        }
        return updatedList;
      });

      const params = {
        email: user.sub.email,
        companyId: user.sub.companyId,
        question: prompt.text + "\n",
        selectedDocumentList: selectedDocumentListParam,
        promptId: prompt.id,
        selectedFolderID: selectedFolderIDParam,
        tender_id: selectedFolderIDParam,
        companyKnowledgeBaseId: companyKnowledgeBaseIdParam,
        documentsForCompanyKnowledgebade: newDocumentsByTab.tab_2.length > 0
          ? [newDocumentsByTab.tab_2[0].document]
          : selectedDocumentList.filter(item => item?.tabName == "tab_2").map(item => item.document),
        isGlobalInsight: prompt.isGlobalInsight || "No"
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/getAnswer`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      });

      if (!response.body) {
        throw new Error('Response body is null');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let isStarted = false;


      setPromptList(prevList => {
        const updatedList = [...prevList];
        const promptToUpdate = updatedList.find(p => p.id === prompt.id);
        if (promptToUpdate) {
          promptToUpdate.isLoading = false;
        }
        return updatedList;
      });
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);

        setPromptList(currentList => {
          const updatedList = [...currentList];
          const promptToUpdate = updatedList.find(p => p.id === prompt.id);

          if (!promptToUpdate) return currentList;

          // Initialize response array if needed
          if (!promptToUpdate.response || typeof promptToUpdate.response === 'string') {
            promptToUpdate.response = [];
          }

          // Find or create response entry for current folder
          let folderResponse = promptToUpdate.response.find(
            item => Object.keys(item)[0] === selectedFolderID
          );

          if (!folderResponse) {
            folderResponse = { [selectedFolderID]: '' };
            promptToUpdate.response.push(folderResponse);
          }

          // Handle the chunk
          const regex = /([^:]*)json_data:(\{.*?\})/g;
          const match = regex.exec(chunkValue);

          if (match && match.length >= 3) {
            const beforeText = match[1];
            if (beforeText) {
              folderResponse[selectedFolderID] += beforeText;
            }
          } else {
            folderResponse[selectedFolderID] += chunkValue;
          }

          return updatedList;
        });
      }

      // Set loading to false after completion


    } catch (error) {
      toast.error(t("Error getting answer: ") + (error instanceof Error ? error.message : 'Unknown error'));
    }
  };

  const handleDelete = async (isGlobal: boolean) => {
    try {
      const data = new FormData();
      data.append("id", prompt.id);
      if (prompt?.isGlobalInsight === "Yes") {
        data.append('isGlobalInsight', isGlobal ? "Yes" : "No");
        data.append("selectedFolderID", selectedFolderID);
      }

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}api/deletePrompt`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
            "Content-Type": "application/json",
          }
        }
      );

      if (result.status === 200) {
        toast.success(t("Successfully Deleted!"));
        setShowDeleteModal(false);
        onUpdate?.();
      }
    } catch (error) {
      toast.error(t("Error deleting prompt"));
    }
  };

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setActiveDropdownIndex(activeDropdownIndex === index ? null : index);
  };

  const insightRefs = useRef<(HTMLDivElement | null)[]>([]);

  const insightContentStyle = `
    max-w-[100%] text-left prose text-gray-600 
    [&>*]:text-base
    [&_p]:text-base
    [&_li]:text-base
    [&_td]:text-base
    [&_th]:text-base
    [&_ul]:text-base
    [&_ol]:text-base
    [&_li]:block
    [&_li]:mb-2
    [&_ol]:list-decimal
    [&_ol]:pl-6
    [&_ol>li]:pl-2
  `;

  const createMarkup = (text: string) => {
    const rawMarkup = marked(text);
    const cleanMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: cleanMarkup };
  };

  const isResponseArray = Array.isArray(prompt.response);
  const formattedResponse = isResponseArray && prompt.response
    ? prompt.response
      .map(item =>
        item.hasOwnProperty(selectedFolderID) ? item[selectedFolderID] : ""
      )
      .join("")
      .replace(/```html|```/g, "")
      .trim()
    : "";

  return (
    <>
      <div className={`relative box-sizing border-box bg-main_bg shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[10px] ${widthClass}`}>
        <div className='flex flex-col text-black'>
          <div className='flex py-[10px] px-[25px]'>
            <span className='text-[20px] font-bold text-left text-black'>{prompt.title}</span>
            <div className="flex-grow"></div>
            <div>
              {membership === "trial" || membership === "basic" || membership === "basic extended" ? (
                <button
                  className="pr-1 cursor-wait"
                  onClick={() => {
                    toast.error(t("Please upgrade your license to use this functionality"), {
                      position: "top-center",
                      autoClose: 1000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                    });
                  }}
                >
                  <InsightRefreshIcon className='text-white' />
                </button>
              ) : (
                <button
                  className="mr-1 cursor-pointer"
                  onClick={getPromptAnswer}
                >
                  <InsightRefreshIcon className='text-white' />
                </button>
              )}
            </div>

            <div
              className="relative"
              ref={ref => (insightRefs.current[index] = ref)}
              onMouseLeave={() => setActiveDropdownIndex(null)}
            >
              <DotsIcon
                onClick={toggleDropdown}
                onMouseEnter={toggleDropdown}
                className="absolute text-white cursor-pointer"
              />
              {activeDropdownIndex === index && (
                <div className="absolute right-[-174px] bg-white shadow rounded z-[1000] p-[10px] w-[150px]">
                  <div
                    className='cursor-pointer mb-[10px] hover:text-[#628ABE]'
                    onClick={() => {
                      setActiveDropdownIndex(null);
                      onEdit?.(prompt);
                    }}
                  >
                    {t("Edit Prompt")}
                  </div>
                  <div
                    className='cursor-pointer hover:text-[#628ABE]'
                    onClick={() => {
                      setActiveDropdownIndex(null);
                      setShowDeleteModal(true);
                    }}
                  >
                    {t("Delete Prompt")}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='my-[20px] flex justify-center px-[25px] safari-scroll scrollbar-hide'>
            {prompt.isLoading ? (
              <div className='flex items-center'>
                <LoadingBar />
              </div>
            ) : (
              isResponseArray && formattedResponse !== "" && (
                <div className='w-full'>
                  <div
                    className={insightContentStyle}
                    dangerouslySetInnerHTML={createMarkup(formattedResponse)}
                  />
                </div>
              )
            )}
          </div>

          {prompt.document && handleDocumentClick && (
            <div
              className='px-[25px] pb-[10px] flex text-left text-black underline break-words cursor-pointer'
              style={{ wordBreak: 'break-word' }}
              onClick={() => handleDocumentClick(prompt.document as string)}
            >
              {prompt.document}
            </div>
          )}
        </div>
      </div>

      {showDeleteModal && (
        <>
          <div className="fixed inset-0 z-[60] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-[360px] my-6 mx-auto md:w-[40%]">
              <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                <div className="flex items-start justify-between p-6 border-b border-solid rounded-t border-slate-200">
                  <h3 className="text-2xl font-semibold text-black">
                    {t("Delete Confirmation Box")}
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-black outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                <div className="flex items-center justify-start p-8 border-t border-solid rounded-b border-slate-200">
                  <h4 className='text-[20px] text-black'>{t("insight_delete_confirm_msg")}</h4>
                </div>

                <div className="flex items-center justify-end p-6 space-x-4 border-t border-solid rounded-b border-slate-200">
                  {prompt.isGlobalInsight === "Yes" && (
                    <>
                      <div className='flex text-black text-[16px] mr-auto cursor-pointer' onClick={() => setIsGlobalDelete(!isGlobalDelete)}>
                        {isGlobalDelete ? <CheckedIcon /> : <UncheckedIcon />}
                        <p className='ml-[5px]'>{t("Delete globally")}</p>
                      </div>
                      <div className='flex text-black text-[16px] mr-auto cursor-pointer' onClick={() => setIsGlobalDelete(!isGlobalDelete)}>
                        {!isGlobalDelete ? <CheckedIcon /> : <UncheckedIcon />}
                        <p className='ml-[5px]'>{t("Delete locally")}</p>
                      </div>
                    </>
                  )}
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    {t("Close")}
                  </button>

                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-700 rounded shadow outline-none active:bg-red-500 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={() => handleDelete(isGlobalDelete)}
                  >
                    {t("Delete")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-50 bg-black opacity-25"></div>
        </>
      )}
    </>
  );
}; 