import { TextElementBox, DocumentEditor, ParagraphWidget } from '@syncfusion/ej2-react-documenteditor';
export const parseJWT = (token: any) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null
  }
};

export const getExtension = (fileName: any) => {
  return fileName.split('.').pop().toLowerCase();
}

export const getUrlsFromText = (text: string): string[] => {
  const urls = text.split(",")
  return urls || [];
}

export const isWebsite = (text: string): boolean => {
  // Check for common website protocols
  if (text.startsWith("http://") || text.startsWith("https://")) {
    return true;
  }

  // Check for common top-level domains
  const tlds: string[] = [".com", ".org", ".net", ".edu", ".gov"];
  const lastPart: string = text.split(".").pop()!;
  if (tlds.includes("." + lastPart)) {
    return true;
  }

  // Use a regular expression to match URLs
  const urlRegex: RegExp = /\b(http|https):\/\/[^\s]+\b/;
  if (urlRegex.test(text)) {
    return true;
  }

  return false;
}

export const getDateLabel = (date: Date, language: string): string => {
  const paramDate = new Date(date);

  // Get the start of the week (Monday) for the provided date
  const startOfWeek = new Date(paramDate);
  const dayOfWeek = startOfWeek.getDay();
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust if Sunday
  startOfWeek.setDate(startOfWeek.getDate() + diffToMonday);
  startOfWeek.setHours(0, 0, 0, 0);

  // Get the end of the week (Sunday) based on the start of the week
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);

  // Format dates to `dd/mm/yy`
  const formatDate = (d: Date) =>
    d.toLocaleDateString(language === 'no' ? 'nb-NO' : 'en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });

  return `${formatDate(startOfWeek)} ~ ${formatDate(endOfWeek)}`;
};


export const formatDate = (dateString: string): string => {
  // Split the input date string into year, month, and day
  const [year, month, day] = dateString.split('/');

  // Get the last two digits of the year
  const yearShort = year.slice(-2);

  // Return the formatted date as DD/MM/YY
  return `${day}/${month}/${yearShort}`;
};


export const isDateInCurrentWeek = (date: Date): boolean => {
  const now = new Date();
  const dayOfWeek = now.getDay();
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)); // Monday
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday
  endOfWeek.setHours(23, 59, 59, 999);

  return date >= startOfWeek && date <= endOfWeek;
};

export const getWeekRange = (date: Date): any => {
  const inputDate = new Date(date);

  // Calculate start of the week (Monday)
  const dayOfWeek = inputDate.getDay();
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust if Sunday
  const startOfWeek = new Date(inputDate);
  startOfWeek.setDate(inputDate.getDate() + diffToMonday);
  startOfWeek.setHours(0, 0, 0, 0);

  // Calculate end of the week (Sunday)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);

  // Extract day, month, year
  return {
    start: {
      day: startOfWeek.getDate(),
      month: startOfWeek.getMonth() + 1, // Months are zero-indexed
      year: startOfWeek.getFullYear(),
    },
    end: {
      day: endOfWeek.getDate(),
      month: endOfWeek.getMonth() + 1, // Months are zero-indexed
      year: endOfWeek.getFullYear(),
    },
  };
};


export const getDateInfo = (date: Date): any => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return { "year": year, "month": month, "day": day };
};

export const getColorByScore = (score: number, cpvCodes, settingCPVCodes) => {
  const isExactMatch = cpvCodes.some(item => settingCPVCodes.includes(item)); // Exact CPV code match
  const isSeriesMatch = cpvCodes.some(item =>
    settingCPVCodes.some(settingItem => item.slice(0, 2) === settingItem.slice(0, 2)) // Same CPV series
  );
  if (isExactMatch && score > 7) {
    // Green: Exact CPV code match AND relevance score >6
    return 'bg-[#43A440]';
  } else if (isSeriesMatch && score > 7) {
    // Green: Same CPV series AND relevance score >7
    return 'bg-[#43A440]';
  } else if (isSeriesMatch || (score >= 4 && score <= 7)) {
    // Yellow: Same CPV series OR relevance score between 4-6
    return 'bg-[#FFC107]';
  } else {
    // Red: Different CPV series AND relevance score <4
    return 'bg-[#E3343A]';
  }
};


export const getColorOfCompilanceScore = (score: number) => {
  if (score == null) {
    return ''
  }
  if (score == 10) {
    return 'bg-[#43A440]';
  } else if (score >= 6) {
    return 'bg-[#FFC107]';
  } else {
    return 'bg-[#E3343A]';
  }
};


export const getTextFromCell = (cell: any) => {
  let cellText = '';
  for (let h = 0; h < cell?.childWidgets?.length; h++) {
    const lineWidgets = cell?.childWidgets[h]?.childWidgets ? cell?.childWidgets[h]?.childWidgets : []
    for (let i = 0; i < lineWidgets.length; i++) {
      const textElementboxes = lineWidgets[i].children
      for (let j = 0; j < textElementboxes.length; j++)
        cellText += textElementboxes[j].text ? textElementboxes[j].text : ''
    }
  }
  return cellText
}

export const removeTextofCell = (cell: any) => {
  const lineWidgets = cell?.childWidgets[0]?.childWidgets ? cell?.childWidgets[0]?.childWidgets : []
  for (let i = 0; i < lineWidgets.length; i++) {
    const textElementboxes = lineWidgets[i].children
    for (let j = 0; j < textElementboxes.length; j++)
      textElementboxes[j].text = ''
  }
}

export const getDescriptionFromRow = (row: any) => {
  const cellWidgets = row?.childWidgets
  const length = cellWidgets.length
  for (let i = 0; i < length; i++) {
    let cellText = ''
    cellText = getTextFromCell(cellWidgets[i])
    if (cellText.length > 10 && (i + 1 < length)) {
      return cellText
    }
  }
  return ""
}


export const getRequirementIndex = (row: any) => {
  const cellWidgets = row?.childWidgets
  const length = cellWidgets.length
  for (let i = 0; i < length; i++) {
    let cellText = ''
    cellText = getTextFromCell(cellWidgets[i])
    if (cellText.length > 10 && (i + 1 < length)) {
      return i
    }
  }
  return 0
}
export const getRemainingDaysAndHours = (tenderDate: string): any => {
  if (tenderDate == "None") {
    return null
  }
  if (tenderDate) {
    const remainingTime = new Date(tenderDate).getTime() - new Date().getTime();
    const remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const remainingHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return { remainingDays, remainingHours };
  }

  return null;
};

export const getFirstSentence = (text: string): string => {
  const regex = /^[^.!?\n]+[.!?\n]/; // Regular expression to match the first sentence
  const match = text.match(regex);
  return match ? match[0] : ''; // Return the matched sentence or an empty string if no match found
};


export const splitTextIntoChunks = (text: string, chunkSize: number): string[] => {
  const chunks: string[] = [];
  for (let i = 0; i < text.length; i += chunkSize) {
    chunks.push(text.slice(i, i + chunkSize));
  }
  return chunks;
}


export const getTenderLength = (tenderList: any[]): number => {
  return tenderList.reduce((totalLength, item) => {
    if (item?.isFolderDeleted !== 1) {
      return totalLength + 1;
    }
    return totalLength;
  }, 0);
}

// Define the utility function
export const getExcelCellText = async (spreadsheet: any, cellKey: any) => {
  try {
    // Fetch data from the given cell key
    const data = await spreadsheet.getData(`${cellKey}`);

    // Check if the data exists and retrieve the cell value
    if (data && data.get(cellKey)) {
      let text = data.get(cellKey).value
      return text != undefined ? text : '';  // Return the value of the cell
    } else {
      return '';  // Return null if the cell has no data
    }
  } catch (error) {
    console.error(`Error retrieving cell data for ${cellKey}:`, error);
    return '';  // Return null in case of an error
  }
};

export const getColumnLetter = (colIndex) => {
  let letter = '';
  while (colIndex >= 0) {
    letter = String.fromCharCode((colIndex % 26) + 65) + letter;
    colIndex = Math.floor(colIndex / 26) - 1;
  }
  return letter;
};

export const getDescriptionFromExcelRow = async (spreadsheet: any, cellRow: any, totalColumns: number) => {
  for (let i = 0; i < totalColumns; i++) {
    // Use custom function to get column letter (e.g., 0 -> "A", 1 -> "B", ...)
    console.log();
    const cellKey = `${getColumnLetter(i)}${cellRow}`
    let cellText = ''
    cellText = await getExcelCellText(spreadsheet, cellKey)
    if (cellText.length > 10 && (i + 1 < totalColumns)) {
      return cellText
    }
  }
  return ""
};

const translations = {
  "no": {
    EXPIRED: "Utløpt",
    AWARDED: "Tildelt",
    CANCELLED: "Avlyst",
    ADVISORY_NOTICE: "Planlegging",
    NOTICE_ON_BUYER_PROFILE: "Planlegging",
    PRE_ANNOUNCEMENT: "Forhåndskunngjøring",
    QUALIFICATION_SCHEME: "Kvalifikasjon",
    DYNAMIC_PURCHASING_SCHEME: "Dynamisk Innkjøpssystem",
    ANNOUNCEMENT_OF_INTENT: "Resultat – Intensjon",
    ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT: "Resultat – Konklusjon",
    CHANGE_OF_CONCLUSION_OF_CONTRACT: "Resultat – Endring av Konklusjon",
    CANCELLED_OR_MISSING_CONCLUSION_OF_CONTRACT: "Resultat – Avlyst"
  },
  "en": {
    EXPIRED: "Expired",
    AWARDED: "Awarded",
    CANCELLED: "Cancelled",
    ADVISORY_NOTICE: "Planning",
    NOTICE_ON_BUYER_PROFILE: "Planning",
    PRE_ANNOUNCEMENT: "Pre Announcement",
    QUALIFICATION_SCHEME: "Qualification",
    DYNAMIC_PURCHASING_SCHEME: "Dynamic Purchasing System",
    ANNOUNCEMENT_OF_INTENT: "Result – Intent",
    ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT: "Result – Conclusion",
    CHANGE_OF_CONCLUSION_OF_CONTRACT: "Result – Change of Conclusion",
    CANCELLED_OR_MISSING_CONCLUSION_OF_CONTRACT: "Result – Cancelled"
  }
};
export const getStatusTag = (status: string, language: string): JSX.Element | null => {
  const text = translations[language][status];
  switch (status) {
    case "EXPIRED":
      return <span className="tag tag_expired bg-gray">{text}</span>;
    case "AWARDED":
      return <span className="tag tag_awarded bg-custom-blue text-white">{text}</span>;
    case "CANCELLED":
      return <span className="tag tag_cancelled bg-custom-red text-white">{text}</span>;
    default:
      return null; // No tag for "ACTIVE"
  }
};

export const isOpportunityFinished = (type: string): boolean => {
  switch (type) {
    case "ANNOUNCEMENT_OF_INTENT":
      return true;
    case "ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT":
      return true
    case "CHANGE_OF_CONCLUSION_OF_CONTRACT":
      return true
    case "CANCELLED_OR_MISSING_CONCLUSION_OF_CONTRACT":
      return true
    default:
      return false
  }
}
export const getTypeTag = (type: string, language: string): JSX.Element | null => {
  const text = translations[language][type];
  switch (type) {
    case "ADVISORY_NOTICE":
      return <span className="tag tag_planning bg-dark-blue text-white">{text}</span>;
    case "NOTICE_ON_BUYER_PROFILE":
      return <span className="tag tag_planning bg-dark-blue text-white">{text}</span>;
    case "PRE_ANNOUNCEMENT":
      return <span className="tag tag_pre_announcement bg-light-blue">{text}</span>;
    case "QUALIFICATION_SCHEME":
      return <span className="tag tag_qualification bg-light-blue">{text}</span>;
    case "DYNAMIC_PURCHASING_SCHEME":
      return <span className="tag tag_dynamic_purchase_system bg-light-blue">{text}</span>;
    case "ANNOUNCEMENT_OF_INTENT":
      return <span className="tag tag_result_intent bg-cyan text-white">{text}</span>;
    case "ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT":
      return <span className="tag tag_result_conclusion bg-cyan text-white">{text}</span>;
    case "CHANGE_OF_CONCLUSION_OF_CONTRACT":
      return <span className="tag tag_result_change_of_conclusion bg-cyan text-white">{text}</span>;
    case "CANCELLED_OR_MISSING_CONCLUSION_OF_CONTRACT":
      return <span className="tag tag_cancelled bg-cyan text-white">{text}</span>;
    default:
      return null; // No tag for "ANNOUNCEMENT_OF_COMPETITION"
  }
};

export const extractQAPairs = (messages: any[]): any[] => {
  const qaPairs: any[] = []

  for (let i = 0; i < messages?.length; i++) {
    if (messages[i]?.isUserMessage) {
      const question = messages?.[i]?.text
      const answerMessage = messages?.[i + 1]
      if (answerMessage && !answerMessage?.isUserMessage) {
        const answer = answerMessage?.text
        qaPairs.push({ question, answer })
      }
    }
  }

  return qaPairs
}
