import React, { useRef, useEffect, useState, useCallback } from 'react'
import "../../../App.css"
import { useTranslation } from 'react-i18next';
import { formatDate, getColorByScore, getDateLabel, getRemainingDaysAndHours, getStatusTag, getTypeTag, getWeekRange, isDateInCurrentWeek, isOpportunityFinished, isWebsite } from '../../../utils';
import { toast } from "react-toastify";
import { parseJWT } from '../../../utils';
const { default: axios } = require('axios');
import { ReactComponent as DotsIcon } from "../../../assets/svg/threedots.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/svg/insight_refresh.svg";
import { ReactComponent as NormalHeart } from "../../../assets/svg/normal.svg";
import { ReactComponent as HoverHeart } from "../../../assets/svg/hover.svg";
import { ReactComponent as ClickedHeart } from "../../../assets/svg/clicked.svg";
import i18n from '../../../i18n';
import { io } from 'socket.io-client';
import { SelectTool, setElementStype } from '@syncfusion/ej2-react-pdfviewer';
import { ReactComponent as Icon1 } from "../../../assets/svg/uncompleted.svg";
import { ReactComponent as Icon2 } from "../../../assets/svg/insight_refresh.svg";
import { ReactComponent as Icon3 } from "../../../assets/svg/complete.svg";
import Calendar from 'react-calendar';
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";

interface OpportunitiesProps {
    setCurrentSlide: any
    opportunities: any[]
    isChatWindowClosed: boolean
    opportuniteisDataFetch: any
    selectedFolderID: any
    setTenderList: any
    selectedTab: string
    setOpenedStatus: any
    handleSelectedFolder: any
    cpvCodes: any[]
    setIsFromModal: any
    promptDataFetch: any
    summaryDataFetch: any
    isSettingFinished: any
    setSettingFinished: any
    setLoadingStatue: any
    tenderList: any
    handleSelectedTab: any
    setAddedState: any
    setDocumentUrl: any
    setDocumentsCount: any
    setCurrentDate: any
    currentDate: any
    setClickedHeartIndex: any
    clickedHeartIndex: any
    setHover: any
    hoverHeartIndex: any
    dateType: any
    setDateType: any
    cpvCodesFetch: any
    setSocketId: any
}
const socketId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

const Socket = io(`${process.env.REACT_APP_API_URL}`, {
    query: { socketId: socketId }
});

const GlobalSocket = io(`${process.env.REACT_APP_API_URL}`);
export const Opportunities: React.FC<OpportunitiesProps> = ({ setHover, hoverHeartIndex, setClickedHeartIndex, clickedHeartIndex, setCurrentDate, currentDate, setDocumentUrl, setDocumentsCount, setAddedState, setCurrentSlide, opportunities, isChatWindowClosed, opportuniteisDataFetch, selectedFolderID, setTenderList, selectedTab, setOpenedStatus, handleSelectedFolder, cpvCodes, setIsFromModal, promptDataFetch, summaryDataFetch, isSettingFinished, setSettingFinished, setLoadingStatue, tenderList, handleSelectedTab, dateType, setDateType, cpvCodesFetch, setSocketId }) => {
    const { t } = useTranslation();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipIndex, setTooltipIndex] = useState<any>(null);
    const [isRefreshing, setRefreshing] = useState(false)
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const [isDownlaoding, setDownloading] = useState(false);
    const [overIndex, setOverIndex] = useState(-1);
    const [currentStep, setCurrentStep] = useState(1);
    const [toastId, setToastId] = useState<any>(null);
    const [stackState, setStackState] = useState<any>(false);
    const [addToWorkSpaceStack, setAddToWorkSpaceStack] = useState<any>([]);
    const [email, setEmail] = useState<any>(null);
    const [pageCount, setPageCount] = useState<any>(1);
    const [tender_id, setTenderId] = useState<any>(null);
    const [unique_id, setUniqueID] = useState<any>(null);
    const topRef = useRef<HTMLDivElement | null>(null);
    const [calenderOpen, setCalendarOpen] = useState(false)
    let user = parseJWT(localStorage.getItem("tender_auth"));
    const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(null);
    const [timeUpdate, setTimeUpdate] = useState(Date.now());
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeUpdate(Date.now());
        }, 3600000);

        return () => clearInterval(timer);
    }, []);

    const getOpportunityFromID = async (tender_id) => {
        const formData: any = new FormData();
        formData.append('email', user.sub.email)
        formData.append('tender_id', tender_id)
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getOpportunityFromID", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200 && result.data.opportunity) {
            setCurrentDate(new Date(result.data.opportunity.date))
            opportuniteisDataFetch(new Date(result.data.opportunity.date))
            if (result.data.opportunity?.isAddedToWorkSpace) {
                toast.warning(t("Already Added to WorkSpace!"), {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                setEmail(null)
                setTenderId(null)
                localStorage.removeItem("email")
                localStorage.removeItem("tender_id")
            } else {
                setTimeout(() => {
                    addToWorkSpace(result.data.opportunity, result.data.opportunity.id, 'tab_1')
                }, 1000);
            }
        }
    }
    const refs = useRef<(HTMLDivElement | null)[]>(Array(opportunities.length).fill(null));
    const toggleDropdown = (index, event) => {
        const currentRef = refs?.current[index]
        if (currentRef &&
            !currentRef.contains(event.target as Node)) {
            // Clicked outside all dropdowns, handle the close action here
            setActiveDropdownIndex(null);
        } else {
            setActiveDropdownIndex(index === activeDropdownIndex ? null : index);
        }
    };

    useEffect(() => {
        GlobalSocket.on('tenderListChanged', async (message) => {
            if (user?.sub?.email === message?.From) {
                await opportuniteisDataFetch(currentDate)
            }
        });
        return () => {
            GlobalSocket.off('tenderListChanged');
        };
    }, []);
    const addNewTender = async (opportunity: any) => {
        Socket.on('filesDownloaded', (message: { data: [] }) => {
            console.log(message.data)
            let newIndex = message.data.length
            setTimeout(setTenderList(message.data), 0)
            setOpenedStatus((prevTabs) => {
                return prevTabs.map((tab) => {
                    if (tab.tabName === selectedTab) {
                        return { ...tab, isOpened: true, openedIndex: newIndex > 0 ? newIndex - 1 : 0 };
                    } else {
                        return tab;
                    }
                });
            });
        });
        let data = new FormData();
        data.append("id", opportunity.id);
        data.append("email", user.sub.email);
        data.append("title", opportunity.tenderText);
        data.append("document", opportunity.tenderURL);
        data.append("customerName", opportunity.customerName);
        data.append("competitionDocsUrl", opportunity?.competitionDocsUrl);
        data.append("date", opportunity.tenderDate)
        data.append("est", opportunity?.estimatedValueAmount ? parseFloat(opportunity?.estimatedValueAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + opportunity?.estimatedValueCurrencyCode : t("N/A"));
        data.append("text", "TenderURL: " + opportunity.tenderURL + "\n" + "TenderText:" + opportunity.tenderText + "\n" + "TenderType:" + opportunity.tenderType + "\n" + "CustomerName:" + opportunity.customerName + "\n" + "TenderDate:" + opportunity.tenderDate + "\n" + "Tender ShortDescription:" + opportunity.shortDescription + "\n");
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/createEmptyWorkSpace", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
        } catch (error) {

        }
        const formData = new FormData();
        formData.append("companyId", user.sub.companyId);
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getTenders", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        setTenderList(result.data.data);
        var newIndex = result.data.data.length;
        setOpenedStatus((prevTabs) => {
            return prevTabs.map((tab) => {
                return { ...tab, isOpened: true, openedIndex: newIndex > 0 ? newIndex - 1 : 0 };
            });
        });
        handleSelectedFolder(result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.id, result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.title)
        if (user?.sub?.membership == "trial") {
            toast.success((newIndex + 1) + " of 3 " + t("allowed Workspaces created!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
        else if (user?.sub?.membership == "basic") {
            toast.success((newIndex) + " of 12 " + t("allowed Workspaces created!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
        else if (user?.sub?.membership == "basic extended") {
            toast.success((newIndex) + " of 24 " + t("allowed Workspaces created!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
        else {
            toast.success(t("Added to WorkSpace!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
    }

    useEffect(() => {
        if (isRefreshing) {
            toast.success(t("Loading tenders"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
    }, [isRefreshing])
    const refreshOpportunities = async () => {
        try {
            if (isSettingFinished == true) {
                const formData: any = new FormData();
                formData.append('email', user.sub.email)
                formData.append("companyId", user.sub.companyId);
                const countResult = await axios.post(process.env.REACT_APP_API_URL + "api/getOpportunitiesCount", formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                setRefreshing(true)
                const date_json = getWeekRange(currentDate)
                const data: any = new FormData();
                data.append('email', user.sub.email)
                data.append('companyId', user.sub.companyId)
                data.append('isSettingFinished', "Yes")
                data.append("start_year", date_json.start.year);
                data.append("start_month", date_json.start.month);
                data.append("start_day", date_json.start.day);
                data.append("end_year", date_json.end.year);
                data.append("end_month", date_json.end.month);
                data.append("end_day", date_json.end.day);
                if (countResult.status === 200) {
                    if (countResult.data.count == 0) {
                        data.append('twoWeeksData', "true")
                        setDateType("week")
                    }
                    else {
                        setDateType("day")
                    }
                    const result = await axios.post(process.env.REACT_APP_API_URL + "api/refreshOpportunities", data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                            "Content-Type": "application/json",
                        }
                    });
                    if (result.status === 200) {
                        await cpvCodesFetch()
                        await opportuniteisDataFetch(currentDate)
                    }
                    setRefreshing(false)
                    setSettingFinished(false)
                }
            }
        } catch (error) {
            setRefreshing(false)
            setSettingFinished(false)
        }
    }
    useEffect(() => {
        refreshOpportunities()
    }, [isSettingFinished]);
    const downloadStack = async () => {
        if (isDownlaoding == false) {
            if (addToWorkSpaceStack.length > 0) {
                let value = addToWorkSpaceStack[0];
                await addToWorkSpace(value.opportunity, value.index, value.tab)
                const updatedItems = addToWorkSpaceStack.slice(1);
                setAddToWorkSpaceStack(updatedItems);
            } else {
                if (stackState == true) {
                    // setAddedState(true);
                    setStackState(false)
                }
            }
        }
    }
    useEffect(() => {
        downloadStack()
    }, [addToWorkSpaceStack, isDownlaoding]);
    const addToWorkSpace = async (opportunity: any, index: any, tab: any) => {
        setSocketId(socketId)
        if ((tenderList.length >= 3 && user?.sub?.membership == "trial") || (tenderList.length >= 12 && user?.sub?.membership == "basic") || (tenderList.length >= 24 && user?.sub?.membership == "basic extended")) {
            toast.error(t("Please upgrade your license to use this functionality"), {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
            })
            return false;
        }
        let heartIndexs = [...clickedHeartIndex, index]
        setClickedHeartIndex(heartIndexs);
        setStackState(true)
        if (isDownlaoding == true) {
            console.log("Already downloading")
            let stackValue = { "opportunity": opportunity, "index": index, "tab": tab }
            let stack = [...addToWorkSpaceStack, stackValue]
            console.log("stact", stack)
            setAddToWorkSpaceStack(stack)
            console.log(stack)
            return false;
        }
        setDownloading(true)
        addNewTender(opportunity)
        setTimeout(async () => {
            if (toastId) {
                toast.dismiss(toastId);
            }
            const id = toast(
                <CustomToast currentStep={1} />,
                {
                    autoClose: false, // Prevent auto-close to control it manually
                    closeOnClick: true,
                    draggable: false,
                    hideProgressBar: true,
                    pauseOnHover: true,
                    progress: 0
                }
            );
            setToastId(id);
            Socket.on('status_update', (message: { status: number }) => {
                setTimeout(() => {
                    console.log("message.status", message.status)
                    toast.update(id, {
                        render: <CustomToast currentStep={message.status} />
                    });
                }, 100)
                if (message.status == 6) {
                    setTimeout(() => {
                        toast.dismiss(id);
                    }, 2000)
                }
            });
            let data = new FormData();
            data.append("id", opportunity.id);
            data.append("email", user.sub.email);
            data.append("companyId", user.sub.companyId);
            data.append("tab", tab ? tab : selectedTab);
            data.append("title", opportunity.tenderText);
            data.append("document", opportunity.tenderURL);
            data.append("customerName", opportunity.customerName);
            data.append("est", opportunity?.estimatedValueAmount ? parseFloat(opportunity?.estimatedValueAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + opportunity?.estimatedValueCurrencyCode : t("N/A"));
            data.append("competitionDocsUrl", opportunity?.competitionDocsUrl);
            data.append("date", opportunity.tenderDate)
            data.append("socketId", socketId)
            data.append("text", "TenderURL: " + opportunity.tenderURL + "\n" + "TenderText:" + opportunity.tenderText + "\n" + "TenderType:" + opportunity.tenderType + "\n" + "CustomerName:" + opportunity.customerName + "\n" + "TenderDate:" + opportunity.tenderDate + "\n" + "Tender ShortDescription:" + opportunity.shortDescription + "\n");
            try {
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/createFolderByTender", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    if (result.data.isexist == "true") {
                        toast.dismiss(id);
                        toast.warning(t("Already Added to WorkSpace!"), {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: 0,
                            toastId: "my_toast",
                        });
                        return false;
                    }
                    let newIndex = result.data.data.filter(item => item.isFolderDeleted === 0).length
                    setTenderList(result.data.data)
                    setOpenedStatus((prevTabs) => {
                        return prevTabs.map((tab) => {
                            if (tab.tabName === selectedTab) {
                                return { ...tab, isOpened: true, openedIndex: newIndex > 0 ? newIndex - 1 : 0 };
                            } else {
                                return tab;
                            }
                        });
                    });
                    handleSelectedFolder(result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.id, result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.title)
                    summaryDataFetch()
                    setDocumentsCount(result.data.documents.length);
                    setDocumentUrl(result.data.documentUrl)
                    setCurrentStep(1)
                    promptDataFetch()
                    setCurrentSlide(1)
                    if (result.data.documents.length > 0) {
                        setIsFromModal(true)
                    } else {
                        toast.dismiss(id);
                    }
                    setTimeout(() => {
                        setDownloading(false)
                    }, 2000)
                    setEmail(null)
                    setTenderId(null)
                    localStorage.removeItem("email")
                    localStorage.removeItem("tender_id")
                } else {
                    summaryDataFetch()
                    setDocumentsCount(0);
                    setDocumentUrl(null);
                    setCurrentStep(1)
                    promptDataFetch()
                    setTimeout(() => {
                        setDownloading(false)
                        toast.dismiss(id);
                    }, 2000)
                    setEmail(null)
                    setTenderId(null)
                    localStorage.removeItem("email")
                    localStorage.removeItem("tender_id")
                }
            } catch (error) {
                summaryDataFetch()
                setDocumentsCount(0);
                setDocumentUrl(null);
                setCurrentStep(1)
                promptDataFetch()
                setTimeout(() => {
                    setDownloading(false)
                    toast.dismiss(id);
                }, 2000)
                setEmail(null)
                setTenderId(null)
                localStorage.removeItem("email")
                localStorage.removeItem("tender_id")
            }
        }, 2000);

    }
    useEffect(() => {
        if (email == user.sub.email && selectedTab == 'tab_1') {
            setTimeout(() => {
                let opportunity = getOpportunityFromID(tender_id)
            }, 1000);
        }
    }, [email, tender_id, selectedTab])
    useEffect(() => {
        setEmail(localStorage.getItem("email"))
        setTenderId(localStorage.getItem("tender_id"))
    }, [])

    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollTo(0, 0);
        }
    };

    const formatBuyerName = (name: string) => {
        if (!name) return "";

        // Hvis navnet er i store bokstaver
        if (name === name.toUpperCase()) {
            // Del opp i ord, formater hvert ord og sett sammen igjen
            return name.toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }

        // Hvis navnet ikke er i store bokstaver, returner det uendret
        return name;
    };

    const filteredOpportunities = opportunities.filter(opportunity => {
        if (!searchQuery) return true;
        return opportunity.tenderText.toLowerCase().includes(searchQuery.toLowerCase()) ||
               opportunity.customerName.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return (
        <div className={`safari-scroll scrollbar-hide h-[300px] md:h-[calc(100vh-115px)]`} ref={topRef}>
            <div className="flex items-center mt-2 relative">
                <div className="flex items-center ml-2 text-xl font-medium text-left text-black flex-grow">
                    <img src={process.env.PUBLIC_URL + '/img/Filter_page1.png'} width={50} height={50} className='mr-[10px]'>
                    </img>
                    <div>
                        <div className='flex'>
                            <span className='font-bold font-["Montserrat"]'>
                                {t("Agent Match Maker")}
                            </span>
                            <div className='flex items-center relative'>
                                {
                                    calenderOpen ? <div className='absolute z-[10000] top-[35px] left-[-45px]'>
                                        <Calendar onChange={(e: any) => {
                                            const dateObject = new Date(e);
                                            if (dateObject.getTime() > new Date().getTime()) {
                                                setCalendarOpen(!calenderOpen)
                                                return
                                            }
                                            setCalendarOpen(!calenderOpen)
                                            setCurrentDate(dateObject)
                                            opportuniteisDataFetch(dateObject)
                                        }} value={currentDate}></Calendar>
                                    </div> : <></>
                                }

                                <svg className="mt-[4px] h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={async () => {
                                    if (currentDate) {
                                        const previousDate = new Date(currentDate);
                                        previousDate.setDate(currentDate.getDate() - 7);
                                        if (dateType == "week") {
                                            setCurrentDate(previousDate);
                                            setDateType("day")
                                        } else {
                                            setDateType("day")
                                            setTimeout(() => {
                                                setCurrentDate(previousDate);
                                            }, 300);
                                        }
                                        const data: any = new FormData();
                                        data.append('email', user.sub.email)
                                        const result = await axios.post(process.env.REACT_APP_API_URL + "api/changeDateType", data, {
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                                "Content-Type": "application/json",
                                            }
                                        });
                                        opportuniteisDataFetch(previousDate)
                                        setPageCount(1)
                                    }
                                }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                                </svg>
                                <span className='font-bold' onClick={() => {
                                    setCalendarOpen(!calenderOpen)
                                }}>
                                    {getDateLabel(currentDate, i18n.language ? i18n.language : "en")}
                                </span>
                                <svg
                                    className={`mt-[4px] h-6 w-6 ${isDateInCurrentWeek(currentDate) ? "text-gray-400 cursor-not-allowed" : "text-black"}`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={async () => {
                                        if (isDateInCurrentWeek(currentDate) || !currentDate) return;

                                        const nextDate = new Date(currentDate);
                                        nextDate.setDate(currentDate.getDate() + 7);
                                        if (dateType === "week") {
                                            setCurrentDate(nextDate);
                                            setDateType("day");
                                        } else {
                                            setDateType("day");
                                            setTimeout(() => {
                                                setCurrentDate(nextDate);
                                            }, 300);
                                        }

                                        const data: any = new FormData();
                                        data.append("email", user.sub.email);
                                        await axios.post(`${process.env.REACT_APP_API_URL}api/changeDateType`, data, {
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                                "Content-Type": "application/json",
                                            },
                                        });

                                        opportuniteisDataFetch(nextDate);
                                        setPageCount(1);
                                    }}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </div>
                            <button onClick={async () => {
                                try {
                                    setRefreshing(true)
                                    const date_json = getWeekRange(currentDate)
                                    const data: any = new FormData();
                                    data.append('email', user.sub.email)
                                    data.append('companyId', user.sub.companyId)
                                    data.append("start_year", date_json.start.year);
                                    data.append("start_month", date_json.start.month);
                                    data.append("start_day", date_json.start.day);
                                    data.append("end_year", date_json.end.year);
                                    data.append("end_month", date_json.end.month);
                                    data.append("end_day", date_json.end.day);
                                    const result = await axios.post(process.env.REACT_APP_API_URL + "api/refreshOpportunities", data, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                            "Content-Type": "application/json",
                                        }
                                    });
                                    if (result.status === 200) {
                                        await opportuniteisDataFetch(currentDate)
                                        setPageCount(1)
                                        setDateType("day")
                                    }
                                    setRefreshing(false)
                                } catch (error) {
                                    setRefreshing(false)
                                }
                            }}>
                                {
                                    isRefreshing ? <svg aria-hidden="true" className="mr-2 text-gray-200 w-7 h-7 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg> : <RefreshIcon className="w-7 h-7"></RefreshIcon>
                                }
                            </button>
                        </div>
                        <div className='text-[15px]'>
                            {t("Agent_Match_Maker_Description")}
                        </div>
                    </div>
                </div>
                
                <div className="flex items-center mr-[10px]">
                    <div className="relative flex items-center w-64">
                        <input
                            type="text"
                            placeholder={t("search_opportunities")}
                            className="w-full px-4 py-2 pr-8 border border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:border-gray-300 text-black"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div className="absolute right-2 text-[#000000] opacity-80">
                            <SearchIcon className="w-5 h-5" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap m-5'>
                {isRefreshing ? (
                    <div className='flex items-center justify-center w-full h-[60vh] text-2xl whitespace-pre text-black'>
                        {t("loading_opportunity_description")}
                    </div>
                ) : filteredOpportunities.length <= 0 ? (
                    <div className='flex items-center justify-center w-full h-[60vh] text-2xl text-black'>
                        {t("no_opportunity_description")}
                    </div>
                ) : (
                    filteredOpportunities.map((opportunity, index) => {
                        return (
                            (index < 21 * pageCount && index >= 21 * (pageCount - 1)) || filteredOpportunities.length < 21
                            ) && (
                                    <div
                                        key={index}
                                        className={`flex flex-col box-sizing border-box shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[10px] ${isChatWindowClosed ? "w-[calc(33.3%-20px)] " : "w-[calc(50%-20px)]"} m-[10px] bg-white relative min-h-[200px]`}
                                    >
                                        <div className="absolute top-[10px] right-[10px] z-10">
                                            <div className='w-[24px] h-[24px] relative'>
                                                {opportunity?.isAddedToWorkSpace == true || clickedHeartIndex.indexOf(opportunity?.id) > -1 ?
                                                    <ClickedHeart
                                                        className="w-full h-full cursor-pointer"
                                                    /> :
                                                    isOpportunityFinished(opportunity?.type) ?
                                                        <></> :
                                                        hoverHeartIndex === opportunity?.id ?
                                                            <HoverHeart
                                                                className="w-full h-full cursor-pointer"
                                                                onClick={(e) => addToWorkSpace(opportunity, opportunity?.id, null)}
                                                                onMouseLeave={() => setHover(-1)}
                                                            /> :
                                                            <NormalHeart
                                                                className="w-full h-full cursor-pointer"
                                                                onClick={(e) => addToWorkSpace(opportunity, opportunity?.id, null)}
                                                                onMouseEnter={() => setHover(opportunity?.id)}
                                                            />
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-col flex-grow">
                                            <div className="flex items-start">
                                                <div className={`${opportunity.color} text-white w-[24px] h-[24px] font-bold flex-none rounded-full flex items-center justify-center mt-[10px] ml-[10px]`}>
                                                    {opportunity.relevanceScore}
                                                </div>

                                                <div className='flex flex-col text-black pl-3 pr-[40px] w-[100%]'>
                                                    <div className='relative flex mt-[10px]'>
                                                        <div className="relative w-full">
                                                            <div
                                                                className='flex flex-col gap-2'
                                                                onMouseEnter={() => {
                                                                    const timeout = setTimeout(() => {
                                                                        if (!tooltipVisible) {
                                                                            setTooltipVisible(true);
                                                                            setTooltipIndex(index);
                                                                        }
                                                                    }, 500);
                                                                    setTooltipTimeout(timeout);
                                                                }}
                                                                onMouseLeave={() => {
                                                                    if (tooltipTimeout) {
                                                                        clearTimeout(tooltipTimeout);
                                                                        setTooltipTimeout(null);
                                                                    }
                                                                    setTimeout(() => {
                                                                        const popup = document.querySelector('.popup-content');
                                                                        if (!popup?.matches(':hover')) {
                                                                            setTooltipVisible(false);
                                                                            setTooltipIndex(null);
                                                                        }
                                                                    }, 200);
                                                                }}
                                                            >
                                                                <span className='text-[18px] font-bold text-left cursor-pointer leading-tight line-clamp-2'
                                                                    onClick={() => { isWebsite(opportunity.tenderURL) ? window.open(opportunity.tenderURL, '_blank') : null; }}
                                                                >
                                                                    {opportunity.tenderText}
                                                                </span>
                                                                <div className='text-[18px] text-left'>
                                                                    {formatBuyerName(opportunity.customerName)}
                                                                </div>
                                                            </div>

                                                            {/* {tooltipVisible && tooltipIndex == index && (
                                                            <div 
                                                                className={`popup-content fixed z-50 bg-white border border-gray-200 p-4 rounded-lg shadow-md w-[1080px] overflow-y-auto`}
                                                                style={{
                                                                    left: '50%',
                                                                    transform: 'translateX(-50%)',
                                                                    top: '2vh',
                                                                    maxHeight: '88vh',
                                                                    height: 'auto'
                                                                }}
                                                                onMouseEnter={() => {
                                                                    setTooltipVisible(true);
                                                                    setTooltipIndex(index);
                                                                }}
                                                                onMouseLeave={() => {
                                                                    setTimeout(() => {
                                                                        const popup = document.querySelector('.popup-content');
                                                                        if (!popup?.matches(':hover')) {
                                                                            setTooltipVisible(false);
                                                                            setTooltipIndex(null);
                                                                        }
                                                                    }, 200);
                                                                }}
                                                            >
                                                                <div className="mb-4 font-bold text-[18px] text-left">{opportunity.tenderText}</div>
                                                                <div className="text-black whitespace-pre-line text-left">{opportunity.shortDescription}</div>
                                                            </div>
                                                        )} */}
                                                            {tooltipVisible && tooltipIndex == index && (
                                                                <>
                                                                    <div className={`absolute top-[calc(100%+10px)] left-1/2 transform -translate-x-1/2 -mt-2`}>
                                                                        <div className="w-8 h-8 transform rotate-45 bg-black border-t-2 border-l-2 border-black border-solid"></div>
                                                                    </div>
                                                                    <div className={`absolute z-50 top-[calc(100%+10px)] bg-white border border-gray-200 p-4 rounded-lg shadow-md safari-scroll max-h-48 scrollbar-hide w-[100%]`}>
                                                                        {opportunity.shortDescription}
                                                                    </div>

                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center ml-[10px] mb-2">
                                            {getTypeTag(opportunity?.type, i18n.language ? i18n.language : "en")}
                                            {getStatusTag(opportunity?.status, i18n.language ? i18n.language : "en")}
                                        </div>

                                        <div className='flex gap-4 text-left text-black text-sm opacity-80 justify-between mx-[10px] mb-[10px]'>
                                            <div className='flex gap-4'>
                                                <div className="flex items-center gap-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#000000"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        width="16"
                                                        height="16"
                                                        strokeWidth="1"
                                                    >
                                                        <path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z"></path>
                                                        <path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4"></path>
                                                        <path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z"></path>
                                                        <path d="M3 6v10c0 .888 .772 1.45 2 2"></path>
                                                        <path d="M3 11c0 .888 .772 1.45 2 2"></path>
                                                    </svg>
                                                    <div>
                                                        {opportunity?.estimatedValueAmount ? parseFloat(opportunity?.estimatedValueAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + opportunity?.estimatedValueCurrencyCode : "-"}
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#000000"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        width="16"
                                                        height="16"
                                                        strokeWidth="1"
                                                    >
                                                        <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z"></path>
                                                        <path d="M16 3v4"></path>
                                                        <path d="M8 3v4"></path>
                                                        <path d="M4 11h16"></path>
                                                        <path d="M7 14h.013"></path>
                                                        <path d="M10.01 14h.005"></path>
                                                        <path d="M13.01 14h.005"></path>
                                                        <path d="M16.015 14h.005"></path>
                                                        <path d="M13.015 17h.005"></path>
                                                        <path d="M7.01 17h.005"></path>
                                                        <path d="M10.01 17h.005"></path>
                                                    </svg>
                                                    <div>{formatDate(opportunity.date) || "-"}</div>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#000000"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        width="16"
                                                        height="16"
                                                        strokeWidth="1"
                                                    >
                                                        <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                                                        <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                                                        <path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727"></path>
                                                        <path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727"></path>
                                                    </svg>
                                                    <div>
                                                        {getRemainingDaysAndHours(opportunity.tenderDate) == null ? "-" :
                                                            `${getRemainingDaysAndHours(opportunity.tenderDate)?.remainingDays}${t("days")} ${getRemainingDaysAndHours(opportunity.tenderDate)?.remainingHours}${t("hours")}`
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                isOpportunityFinished(opportunity?.type) ? <></> :
                                                    <div className="relative">
                                                        <div
                                                            className={`p-1 min-w-[32px] h-8 font-bold text-center text-black align-middle text-[16px] cursor-pointer`}
                                                            onMouseOver={() => {
                                                                setOverIndex(opportunity.id)
                                                            }}
                                                            onMouseOut={() => { setOverIndex(-1) }}
                                                            onClick={() => {
                                                                if (opportunity?.competitionDocsUrl) {
                                                                    window.open(opportunity.competitionDocsUrl, '_blank');
                                                                }
                                                            }}
                                                        >
                                                            {overIndex == opportunity?.id ?
                                                                opportunity?.cartTextHover :
                                                                opportunity?.cartText
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                );
                        })
                    )
                }

                {!isRefreshing && filteredOpportunities.length >= 21 &&
                    (
                        <div className='flex w-[90%] h-[50px] text-base justify-end'>
                            <div className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex "text-black bg-white" justify-center items-center text-center border rounded-md cursor-pointer`} onClick={() => {
                                if (pageCount == 1) return
                                setPageCount(pageCount - 1)
                            }}><svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd"></path>
                                </svg>
                            </div>
                            {filteredOpportunities.map((opportunity, index) => {
                                return index % 21 === 0 && <div className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex ${pageCount === index / 21 + 1 ? "text-white bg-main_color_2" : "text-black bg-white"} justify-center items-center text-center border rounded-md cursor-pointer`} key={index / 21} onClick={() => {
                                    setPageCount(index / 21 + 1)
                                    scrollToTop()
                                    console.log(pageCount)
                                }}>{index / 21 + 1}</div>
                            })}
                            <div className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex "text-black bg-white" justify-center items-center text-center border rounded-md cursor-pointer`} onClick={() => {
                                if (pageCount >= filteredOpportunities.length / 21) return
                                setPageCount(pageCount + 1)
                            }}><svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path>
                                </svg></div>
                        </div>
                    )
                }
            </div>
        </div>
    );

}

interface CustomToastProps {
    currentStep: number;
}

// Your custom toast component with TypeScript
const CustomToast: React.FC<CustomToastProps> = ({ currentStep }) => {
    console.log("currentStep", currentStep)
    const { t } = useTranslation();
    return (
        <div>
            <div className='flex items-center p-1'>
                {currentStep === 1 ? <Icon2 className='animate-spin' /> : <Icon3 />} &nbsp;&nbsp;{t("Documents downloaded")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 2 ? <Icon2 className='animate-spin' /> : currentStep > 2 ? <Icon3 /> : <Icon1 />} &nbsp;&nbsp;{t("AI learning the documents")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 3 ? <Icon2 className='animate-spin' /> : currentStep > 3 ? <Icon3 /> : <Icon1 />} &nbsp;&nbsp;{t("Getting Insights")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 4 ? <Icon2 className='animate-spin' /> : currentStep > 4 ? <Icon3 /> : <Icon1 />} &nbsp;&nbsp;{t("Finding_documents_for_submission")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 5 ? <Icon2 className='animate-spin' /> : currentStep > 5 ? <Icon3 /> : <Icon1 />} &nbsp;&nbsp;{t("Getting Summaries")}
            </div>
        </div>
    )
}