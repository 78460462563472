import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerLicense } from '@syncfusion/ej2-base';
import AudioRecorder from 'audio-recorder-polyfill'
import useGlobalErrorHandler from "./useGlobalErrorHandler";
import ErrorPage from "./ErrorPage";


window.MediaRecorder = AudioRecorder

registerLicense('ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdEdiWX5ddHxXT2Ba');

const Main: React.FC = () => {
  const hasError = useGlobalErrorHandler();

  if (hasError) {
    return <ErrorPage />;
  }
  return (
    <App />
  );
};

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
