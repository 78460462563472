// TreeItem.tsx
import React, { useState, useEffect } from 'react';

interface TreeItemProps {
    id: string, 
    label: string;
    isChecked: boolean;
    onCheckboxChange: (isChecked: boolean) => void;
    children?: React.ReactNode;
}

const TreeItem: React.FC<TreeItemProps> = ({ id, label, isChecked, onCheckboxChange, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const checkChildrenRecursively = (child: any): boolean => {
            if (child.type?.name === 'Tree') {
                return child.props.checkedItems.some((checkedId: string) => {
                    const item = child.props.data.find((item: any) => item.id === checkedId);
                    if (item) {
                        if (item.children?.length > 0) {
                            return true;
                        }
                        return true;
                    }
                    return child.props.data.some((item: any) => 
                        item.children?.some((subItem: any) => 
                            checkItemRecursively(subItem, checkedId)
                        )
                    );
                });
            }
            return false;
        };

        const checkItemRecursively = (item: any, checkedId: string): boolean => {
            if (item.id === checkedId) return true;
            if (item.children?.length > 0) {
                return item.children.some((child: any) => checkItemRecursively(child, checkedId));
            }
            return false;
        };

        const shouldExpand = React.Children.toArray(children).some(checkChildrenRecursively);

        if (isChecked && !shouldExpand) {
            setIsExpanded(false);
        } else if (shouldExpand) {
            setIsExpanded(true);
        }
    }, [children, isChecked]);

    const handleExpandToggle = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div>
            <div className="flex items-start">
                {children && (
                    <button className="mr-2 focus:outline-none]" onClick={handleExpandToggle}>
                        {isExpanded ? '-' : '+'}
                    </button>
                )}
                <label className="flex items-baseline space-x-2">
                    <input
                        type="checkbox"
                        className="w-4 h-4 appearance-none checked:bg-orange-500 bg-white border border-gray-300 rounded focus:outline-none checked:border-transparent"
                        style={{
                            backgroundImage: 'url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e")',
                            backgroundSize: '100% 100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                        checked={isChecked}
                        onChange={(e) => onCheckboxChange(e.target.checked)}
                    />
                    <span>{`${id} ${label}`}</span>
                </label>
            </div>
            {isExpanded && <div className="ml-4">{children}</div>}
        </div>
    );
};

export default TreeItem;