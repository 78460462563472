import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { parseJWT } from '../../../utils';
const { default: axios } = require('axios');
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

interface ArchivedProps {
    isOpened: any
    documentClick: any
    collaspe: any
    documents: any
    openedDocument: string
}

export const Archived: React.FC<ArchivedProps> = ({ isOpened, documentClick, collaspe, documents, openedDocument}) => {
    let user = parseJWT(localStorage.getItem("tender_auth"));
    const [archivedList, setArchivedList] = useState<any>([]);
    const { t } = useTranslation();

    const handleDocumentClick = (document: string) => {
        documentClick(document, "deleted");
    }

    useEffect(() => {
        let data: any = []
        for (let i = 0; i < documents?.length; i++) {
            if (documents[i]?.deleted_documents?.length > 0) {
                for(let j = 0; j < documents[i]?.deleted_documents?.length; j++) {
                    let item = {
                        document_index: i,
                        deleted_document_index: j,
                        deleted_document: documents[i].deleted_documents[j]
                    }
                    data.push(item)
                }
            }
        }
        setArchivedList(data);
    }, [documents])

    return (
        <div className="flex-col overflow-hidden hidden">
            <div className={isOpened ? `flex flex-col` : `invisible`} >
                <div className="p-2 font-light h-[90px]  border-dotted	border-[2px] border-orange-500"  >
                    <Droppable droppableId={`archived_droppable_0`}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <div className='safari-scroll w-full scrollbar-hide h-[80px] '>
                                    {
                                        archivedList.length > 0 ? archivedList.map((document: any, i: any) => {
                                            return <Draggable key={i} draggableId={`archived_` +document.document_index+ "_" + document.deleted_document_index} index={document.deleted_document_index}>
                                                {(provided: any, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <p key={i} className={`text-black cursor-pointer document-title ${openedDocument == document.deleted_document ? "font-semibold" : ""}`}  onClick={() => handleDocumentClick(document.deleted_document)}>{document.deleted_document}</p>
                                                    </div>
                                                )}
                                            </Draggable>
                                        }) : (
                                            <Draggable key={0} draggableId={`archived_0` + "_" + 0} index={0}>
                                                {(provided: any, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <p className="text-black cursor-pointer h-[1px]" >&nbsp;</p>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    }
                                </div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
            <div>
                <h2>
                    <div
                        className="flex items-center justify-between w-full p-2 font-medium text-left bg-[#555] border border-b-0 border-gray-200 cursor-pointer">
                        <span className="flex items-center" onClick={(e) => collaspe()}>
                            {
                                isOpened ?
                                    <svg className="w-5 h-5 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 15 12 9 18 15" /></svg>
                                    : <svg className="w-5 h-5 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg>
                            }
                            <svg className="w-5 h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                            <span className='ml-[3px] text-white'>
                            {t('Archived')}
                            </span>
                        </span>
                    </div>
                </h2>



            </div>
        </div>
    )
}