import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en/translation.json';
import noTranslation from './locales/no/translation.json';
const userLang = navigator.language
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      no: {
        translation: noTranslation,
      },
    },
    fallbackLng: 'en', // fallback language if translation for the detected language is not available
    interpolation: {
      escapeValue: false, // React already escapes variables to prevent XSS
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;