import React, { useEffect, useState, useRef } from 'react';
import i18n from '../../../i18n';
const { default: axios } = require('axios');

type DropdownProps = {
    documents: string[];
    selectedFolderID: string
    description: string
    getDocumentsForSubmission: any
    clearDropdownData: any
    dropdownIndex: any
    dropdownDoc: any
    index: number
};

const Dropdown: React.FC<DropdownProps> = ({ documents, selectedFolderID, description, getDocumentsForSubmission, clearDropdownData, dropdownIndex, dropdownDoc, index }) => {
    const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const language = i18n.language || "en"
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const listRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (dropdownDoc != null && index === dropdownIndex) {
            setSelectedDocument(dropdownDoc)
        }
    }, [dropdownDoc])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
                clearDropdownData();
                setSearchTerm('');
                setHighlightedIndex(-1);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [clearDropdownData]);

    const handleSelect = async (document: string) => {
        setSelectedDocument(document);
        setIsOpen(false);
        clearDropdownData();
        setSearchTerm('');
        setHighlightedIndex(-1);
        if (document !== 'Select a document' && document !== 'Velg et dokument') {
            const formData: any = new FormData();
            formData.append('tender_id', selectedFolderID)
            formData.append('doc_id', document)
            formData.append('description', description)
            formData.append('status', 'Uncomplete')
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/saveChangesSubmission", formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                getDocumentsForSubmission("normal")
            }
        }
    };

    // Split into two separate handlers
    const handleButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (!isOpen) {
            setIsOpen(true);
            if (e.key.length === 1) { // Only handle printable characters
                setSearchTerm(e.key);
            }
        }
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const filteredDocs = documents?.filter(doc => 
            doc.toLowerCase().includes(searchTerm.toLowerCase())
        ) || [];

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setHighlightedIndex(prev => 
                    prev < filteredDocs.length - 1 ? prev + 1 : prev
                );
                break;
            case 'ArrowUp':
                e.preventDefault();
                setHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
                break;
            case 'Enter':
                e.preventDefault();
                if (highlightedIndex >= 0 && filteredDocs[highlightedIndex]) {
                    handleSelect(filteredDocs[highlightedIndex]);
                }
                break;
            case 'Escape':
                setIsOpen(false);
                setSearchTerm('');
                setHighlightedIndex(-1);
                break;
        }

        // Scroll highlighted item into view
        if (highlightedIndex >= 0 && listRef.current) {
            const highlightedElement = listRef.current.children[highlightedIndex] as HTMLElement;
            if (highlightedElement) {
                highlightedElement.scrollIntoView({ block: 'nearest' });
            }
        }
    };

    // Filter documents based on search
    const filteredDocuments = documents?.filter(doc => 
        doc.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

    return (
        <div className="relative inline-block w-full" ref={dropdownRef}>
            <button
                ref={buttonRef}
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={handleButtonKeyDown}
                className="w-full p-2 text-black bg-white-600 rounded-lg shadow no-focus-visible text-left"
            >
                {searchTerm || selectedDocument || (language == 'en' ? 'Select a document' : 'Velg et dokument')}
            </button>

            {isOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        placeholder={language == 'en' ? 'Search documents...' : 'Søk i dokumenter...'}
                        className="w-full p-2 border-b border-gray-300 rounded-t-lg no-focus-visible"
                        autoFocus
                    />
                    <ul ref={listRef} className="max-h-[250px] overflow-y-auto safari-scroll">
                        {filteredDocuments.map((document, index) => (
                            <li
                                key={index}
                                onClick={() => handleSelect(document)}
                                className={`px-4 py-2 cursor-pointer ${
                                    index === highlightedIndex ? 'bg-blue-100' : 'hover:bg-blue-50'
                                }`}
                            >
                                {document}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
