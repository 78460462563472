import React, { useEffect, useState, useRef } from 'react'
import { toast } from "react-toastify";
import { parseJWT, getUrlsFromText, isWebsite, getRemainingDaysAndHours } from '../../../utils';
const { default: axios } = require('axios');
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as CheckedIcon } from "../../../assets/svg/checked.svg"
import { ReactComponent as UncheckedIcon } from "../../../assets/svg/unchecked.svg";
import { ReactComponent as UncheckedFolderIcon } from "../../../assets/svg/uncheckedfolder.svg";
import { ReactComponent as UncheckedDocumentIcon } from "../../../assets/svg/uncheckeddocument.svg";
import { ReactComponent as newWindowIcon } from "../../../assets/svg/new_window.svg";
import { LoadingBar } from '../../LoadingBar';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { ReactComponent as DotsIcon } from "../../../assets/svg/threedots.svg";
import StatusTagsDropdown from "./StatusTagsDropdown"
import { CreateDocumentModal } from './CreateDocumentModal';

interface AccordionProps {
    index: any
    tender: any
    saveTender: any
    dbSaveSuccess: any
    collaspe: any
    isOpened: any
    documentClick: any
    tabName: any
    openedDocument: string
    handleMetadata: any
    handleSelectedDocuments: any
    isAnyItemOpen: boolean,
    summaryDataFetch: any,
    setPreviousOpenedDocument: any
    selectedDocumentList: any,
    setSelectedDocumentList: any
    setCurrentSlide: any
    docList: any
    uploadedFilesWithoutFolder: any
    setUploadedFilesWithoutFolder: any
    promptDataFetch: any
    top: any
    setTenderList: any
    handleSelectedFolder: any
    setTabName: any
    handleSelectedTab: any
    setMovedState: any
    setOpenedStatus: any
    setCurrentPage: any
    currentPage: any
}

export const Accordion: React.FC<AccordionProps> = ({ index, tender, saveTender, dbSaveSuccess, collaspe, isOpened, documentClick, tabName, openedDocument, handleMetadata, handleSelectedDocuments, isAnyItemOpen, summaryDataFetch, setPreviousOpenedDocument, selectedDocumentList, setSelectedDocumentList, setCurrentSlide, docList, uploadedFilesWithoutFolder, setUploadedFilesWithoutFolder, promptDataFetch, top, setTenderList, handleSelectedFolder, setTabName, handleSelectedTab, setMovedState, setOpenedStatus, setCurrentPage, currentPage }) => {
    const [tender_title, setTitle] = useState(tender.title);
    const [isEditing, setEditing] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [documents, setDocuments] = useState<string[]>(tender?.documents);
    const [tender_id, setTenderId] = useState(tender?.id);
    const [scrollTop, setScrollTop] = useState<any>(0);
    const [summaryCreateStarted, setSummaryCreateStarted] = useState<Number>(-1)
    const [isSaveStarted, setIsSaveStarted] = useState(false)
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const dropdownRef_1 = useRef<HTMLDivElement | null>(null);
    const [activeDropdownIndex_1, setActiveDropdownIndex_1] = useState(null);
    const [activeDropdownIndex_2, setActiveDropdownIndex_2] = useState(null);
    const [activeTab, setActiveTab] = useState(tabName == "tab_1" ? "tab_1" : "tab_");
    const [tabData, setTabData] = useState<any[]>([]);
    const [showDeleteFileModal, setShowDeleteFileModal] = useState(false)
    const [isFolderDelete, setIsFolderDelete] = useState(false)
    const [isDraggingFiles, setIsDraggingFiles] = useState(false);
    let user = parseJWT(localStorage.getItem("tender_auth"));
    const [showModal, setShowModal] = useState(false);

    const toggleDropdown_1 = (index, event) => {
        setScrollTop(event.pageY - 150)
        if (dropdownRef_1.current &&
            !dropdownRef_1.current.contains(event.target as Node)) {
            // Clicked outside all dropdowns, handle the close action here
            setActiveDropdownIndex_1(null);
        } else {
            setActiveDropdownIndex_1(index === activeDropdownIndex_1 ? null : index);
        }
    };

    useEffect(() => {
        if (tender?.documents) {
            setDocuments(tender?.documents);
        }
    }, [tender?.documents])
    useEffect(() => {
        setTenderId(tender?.id);
    }, [tender?.id])
    const toggleAccordion = () => {
        if (tender.isSaved || (tabName === "tab_2" && tender.title === "Company Documents")) {
            collaspe(index, !isOpened);
        }
    }

    useEffect(() => {
        setEditing(false);
        setActiveTab(tabName == "tab_1" ? "tab_2" : "tab_1")
    }, [tabName])

    useEffect(() => {
        if (isAnyItemOpen) {
            if (isOpened) {
                const jsonArrayOfDocuments = documents.map(document => ({ document, tabName, folderName: tender_title }));
                let selectedList: any[] = selectedDocumentList.filter(item => item?.tabName == tabName && item?.folderName == tender_title)
                handleSelectedDocuments(selectedDocumentList, jsonArrayOfDocuments, selectedList.length === 0 || selectedList.length === jsonArrayOfDocuments.length ? true : false)
            }
        } else {
            handleSelectedDocuments([], [], false)
        }
    }, [selectedDocumentList, documents, isOpened])

    useEffect(() => {
        setScrollTop(top)
    }, [top])
    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const mouseX = event.clientX; // X coordinate relative to the viewport
        const mouseY = event.clientY; // Y coordinate relative to the viewport
        const pageX = event.pageX;    // X coordinate relative to the document
        const pageY = event.pageY;    // Y coordinate relative to the document

        console.log('Mouse X (client):', mouseX);
        console.log('Mouse Y (client):', mouseY);
        console.log('Mouse X (page):', pageX);
        console.log('Mouse Y (page):', pageY);
    };
    const handleSave = async (id: any) => {
        const createdTender: any = await saveTender(id, tender_title);
        if (createdTender && uploadedFilesWithoutFolder?.length > 0) {
            const data = new FormData();
            data.append("id", createdTender.id);
            data.append("email", user.sub.email);
            data.append('tab', createdTender.tab);
            data.append('folder', createdTender.title);
            for (let i = 0; i < uploadedFilesWithoutFolder.length; i++) {
                data.append("file", uploadedFilesWithoutFolder[i]);
            }
            try {

                const result = await axios.post(process.env.REACT_APP_API_URL + "api/uploadDocument", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    toast.success(t("Document Uploaded!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    dbSaveSuccess();
                }
            } catch (error) {
                console.log(error)
            }

            setUploadedFilesWithoutFolder([]);
        }
        setEditing(false);
    }

    const editTender = () => {
        setEditing(true);
        setTitle(tender.title);
    }

    const handleDrop = (event: any) => {
        event.preventDefault();
        const chosenFiles = event.dataTransfer.files;
        const allowedExtensions = /(\.pdf|\.xlsx|\.docx)$/i;
        const uploaded: any = [...uploadedFiles];

        for (let i = 0; i < chosenFiles.length; i++) {
            const file = chosenFiles[i];
            const name = file.name;

            if (uploaded.findIndex((f: any) => f.name === name) === -1 && allowedExtensions.test(name)) {
                uploaded.push(file);
            }
        }
        setUploadedFiles(uploaded)
        handleAddDocument(uploaded);
    };
    const handleFileInputChange = (event: any) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        const allowedExtensions = /(\.pdf|\.xlsx|\.docx)$/i;
        const uploaded: any = [...uploadedFiles];
        chosenFiles.some((file) => {
            let name = file.name;
            if (uploaded.findIndex((f: any) => f.name === file.name) === -1 && allowedExtensions.exec(name)) {
                uploaded.push(file);
            }
        })
        setUploadedFiles(uploaded)
        handleAddDocument(uploaded);
    };

    const handleAddDocument = async (uploadedFiles: any) => {
        if (uploadedFiles.length === 0) {
            toast.error(t("Please select file to add."), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
            return;
        }

        const data = new FormData();
        data.append("id", tender.id);
        data.append("email", user.sub.email);
        data.append('tab', tender.tab);
        data.append('folder', tender.title);
        for (let i = 0; i < uploadedFiles.length; i++) {
            data.append("file", uploadedFiles[i]);
        }
        try {

            const result = await axios.post(process.env.REACT_APP_API_URL + "api/uploadDocument", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                let documentsLen = tender.documents.length
                toast.success(t("Document Uploaded!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                dbSaveSuccess();
                if (tabName != "tab_2") {
                    createSummaryRecursive(result?.data?.fileNames, 0, documentsLen)
                }
            }
        } catch (error) {
            console.log(error)
        }

        setUploadedFiles([]);
    }

    const createSummaryRecursive = async (fileNames: string[], currentIndex: number, documentsLen: number) => {
        if (currentIndex < fileNames.length) {
            const document = fileNames[currentIndex];
            const index = documentsLen + currentIndex;

            await handleSummaryCreate(document, index);

            // Call the next iteration
            await createSummaryRecursive(fileNames, currentIndex + 1, documentsLen);
        }
    }

    const handleDocumentClick = (index: number, document: string) => {
        let selectedList: any[] = selectedDocumentList.filter(item => item?.tabName !== tabName)
        setSelectedDocumentList([...selectedList, { document: document, tabName: tabName, folderName: tender_title }]);
        setCurrentSlide(3);
        documentClick(document, "direct-open", tender_title, tender.id);
    }

    const handleSaveNewDocument = async (documentName: string) => {
        const data = new FormData();
        data.append("id", tender.id);
        data.append("email", user.sub.email);
        data.append("name", documentName);
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/createNewDocument", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                toast.success(t("Document Created!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                dbSaveSuccess();
            }
        } catch (error) {
            console.log(error)
        }
    };

    const handleImportWebsite = async (urls: string, frequency: number, createSummary: boolean) => {
        let data = {
            "id": tender.id,
            "email": user.sub.email,
            "tab": tender.tab,
            "folder": tender.title,
            "urls": getUrlsFromText(urls),
            "type": frequency,
            "isSummaryCreate": createSummary,
        }
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/trainFromWebsite", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                toast.success(t("Imported Website(s)!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                dbSaveSuccess();
                summaryDataFetch();
            }
        } catch (error) {
            toast.error(t("Error importing website"));
        }
    };

    const selectDocument = (index: number, document: string) => {
        setSelectedDocumentList(list => [...list, { document: document, tabName: tabName, folderName: tender_title }]);
        if (tabName != "tab_2")
            setPreviousOpenedDocument(document)
    }

    const unselectDocument = (index: number, document: string) => {
        setSelectedDocumentList(list => list.filter(item => item.document !== document));
    }

    const handleSummaryCreate = async (document: string, index: number) => {
        console.log(index)
        setSummaryCreateStarted(index)
        try {
            let data = {
                "id": tender.id,
                "email": user.sub.email,
                "companyId": user.sub.companyId,
                "tab": tender.tab,
                "folder": tender.title,
                "document": document,
                "isWebsite": isWebsite(document),
                "language": i18n.language ? i18n.language : "en"
            }
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/createSummary", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                toast.success(t("Summary Created!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                setCurrentSlide(1)
                summaryDataFetch();
            }
        } catch (error) {
            console.log(error)
        }
        setSummaryCreateStarted(-1)
    }

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);

    useEffect(() => {
        // Function to handle clicks outside the dropdown
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef_1.current &&
                !dropdownRef_1.current.contains(event.target as Node)
            ) {
                setActiveDropdownIndex_1(null);
            }
        };

        // Add event listener to handle clicks outside the dropdown
        document.addEventListener('click', handleClickOutside);

        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const moveFilesToTab = async (from: any, to: any, active_tab: string, documentList: any[]) => {
        // console.log(from, to, active_tab, documentList)
        documentList = documentList.map(({ document }) => document);
        const from_documents = from?.documents?.filter(item => !documentList.includes(item));
        const to_documents = [...to?.documents, ...documentList];
        const data = {
            from_id: from?.id,
            to_id: to?.id,
            from_documents: from_documents,
            to_documents: to_documents
        }
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/moveTenderDocuments", data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200) {
            toast.success(t("Successfully Updated!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
            dbSaveSuccess();
            setSelectedDocumentList([])
            setActiveDropdownIndex_1(null);
            setActiveDropdownIndex_2(null);
        }
    }

    const deleteFiles = async (from: any, documentList: any[]) => {
        documentList = documentList.map(({ document }) => document);
        const documents = from?.documents?.filter(item => !documentList.includes(item));
        const deleted_documents = [...(from?.deleted_documents ?? []), ...documentList];
        const data = {
            'email': user.sub.email,
            "id": from?.id,
            "documents": documents,
            "deleted_documents": isFolderDelete ? from?.documents : deleted_documents,
            "isFolderDelete": isFolderDelete
        }

        const result = await axios.post(process.env.REACT_APP_API_URL + "api/deleteFiles", data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200) {
            toast.success(t("Successfully Deleted!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
            dbSaveSuccess();
            promptDataFetch();
            setActiveDropdownIndex_1(null);
            setSelectedDocumentList([])
        }
        setShowDeleteFileModal(false)
        setIsFolderDelete(false)
    }

    useEffect(() => {
        // Force re-render av drop-sonen når fanen endres
        setUploadedFiles([...uploadedFiles]);
    }, [tabName]);

    useEffect(() => {
        if (tabName === "tab_2" && tender.title === "Company Documents" && !isOpened) {
            toggleAccordion();
        }
    }, [tabName]);

    const createNewDocument = () => {
        setShowModal(true);
    };

    return (
        <>
            <div className="flex flex-col">
                <div className='mt-2'>
                    <Droppable droppableId={`accordion_header_` + index}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <h2>
                                    <div
                                        className={`flex items-center justify-between w-[calc(100%-12px)] font-medium text-left rounded-[10px] ${
                                            isOpened 
                                                ? `bg-transparent border-[3px]` 
                                                : `bg-transparent border-[1px] hover:border-[3px] hover:font-bold transition-all duration-200`
                                        } border-orange-500 cursor-pointer mx-[6px]`}>
                                        <div className='w-full p-2 flex items-center' onClick={(e) => toggleAccordion()}>
                                            <div className="flex-shrink-0 min-w-[24px] mr-[12px]">
                                                {
                                                    isOpened ? selectedDocumentList.filter(item => item?.tabName == tabName).length > 0 ? 
                                                        <UncheckedFolderIcon></UncheckedFolderIcon> :
                                                        <CheckedIcon></CheckedIcon> : 
                                                        <svg className="w-5 h-5 text-black" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  
                                                            <path stroke="none" d="M0 0h24v24H0z" />  
                                                            <polyline points="9 6 15 12 9 18" />
                                                        </svg>
                                                }
                                            </div>
                                            <span className={`text-black leading-tight ${isOpened ? 'font-bold' : 'font-normal'} flex-grow`}>
                                                {tender.isSaved && !isEditing ? t(tender.title) : <input ref={inputRef} className={`bg-transparent w-[98%] leading-tight ${isOpened ? 'font-bold' : 'font-normal'} text-black`} value={tender_title} onChange={(e) => setTitle(e.target.value)} onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSave(tender.id)
                                                    }
                                                }}></input>}
                                            </span>
                                        </div>
                                        <div className='flex p-1'>
                                            {
                                                tender.isSaved && !isEditing ?
                                                    <></> :
                                                    <svg onClick={(e) => handleSave(tender.id)} className="h-8 w-8 text-white mr-[-10px]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />  <polyline points="17 21 17 13 7 13 7 21" />  <polyline points="7 3 7 8 15 8" /></svg>
                                            }
                                            <div ref={dropdownRef_1} className="flex items-center justify-end static w-[100%]">
                                                <svg className="cursor-pointer" width="26" height="26" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => toggleDropdown_1(index, e)}>
                                                    <ellipse cx="14.8346" cy="12" rx="1.16667" ry="1" stroke="black" strokeWidth="2"
                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                    <ellipse cx="14.8346" cy="19" rx="1.16667" ry="1" stroke="black" strokeWidth="2"
                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                    <ellipse cx="14.8346" cy="5" rx="1.16667" ry="1" stroke="black" strokeWidth="2"
                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                {activeDropdownIndex_1 === index && (
                                                    <div className={`absolute right-[-200px] bg-white shadow rounded z-[1000] p-[10px] w-[200px] pl-[20px] text-left mt-[50px] text-black`} style={{ zIndex: "1000", top: `${scrollTop}px` }}>
                                                        <div 
                                                            onClick={() => {
                                                                createNewDocument();
                                                                setActiveDropdownIndex_1(null);
                                                            }} 
                                                            className={isOpened ? `cursor-pointer hover:text-hover_orange flex flex-row items-center text-black rounded` : `hidden`}
                                                        >
                                                            {t('Create_doc_Import_Website')}
                                                        </div>
                                                        <div 
                                                            className='cursor-pointer hover:text-hover_orange' 
                                                            onClick={() => {
                                                                editTender();
                                                                setEditing(true);
                                                                setActiveDropdownIndex_1(null);
                                                            }}
                                                        >
                                                            {t("Edit Folder Name")}
                                                        </div>
                                                        <div 
                                                            className={`cursor-pointer ${selectedDocumentList.length > 0 ? 'hover:text-hover_orange' : 'text-gray-400 cursor-not-allowed'}`} 
                                                            onClick={() => {
                                                                if (selectedDocumentList.length > 0) {
                                                                    setActiveDropdownIndex_2(index === activeDropdownIndex_2 ? null : index);
                                                                }
                                                            }}
                                                        >
                                                            {t("Move selected files to")}
                                                        </div>
                                                        <div 
                                                            className={`cursor-pointer ${selectedDocumentList.length > 0 ? 'hover:text-hover_orange' : 'text-gray-400 cursor-not-allowed'}`} 
                                                            onClick={() => {
                                                                if (selectedDocumentList.length > 0) {
                                                                    setShowDeleteFileModal(true);
                                                                    setIsFolderDelete(false);
                                                                    setActiveDropdownIndex_1(null);
                                                                }
                                                            }}
                                                        >
                                                            {t("Delete selected files")}
                                                        </div>
                                                        <div 
                                                            className='cursor-pointer hover:text-hover_orange' 
                                                            onClick={() => {
                                                                setShowDeleteFileModal(true);
                                                                setIsFolderDelete(true);
                                                                setActiveDropdownIndex_1(null);
                                                            }}
                                                        >
                                                            {t("Delete folder and files")}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </h2>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div className={isOpened ? `flex flex-col relative` : `invisible h-[0px] relative`} >
                        {tender?.title == "Company Documents" ? <></> :
                            <div className='absolute flex justify-end top-4 right-4'>
                                <a className='w-5 h-5 cursor-pointer ' href={tender?.metadata?.competitionDocsUrl} target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 17.5" fill="none" x="0px" y="0px">
                                        <path d="M11.2 6.174C11.0143 6.174 10.8363 6.24775 10.705 6.37902C10.5737 6.5103 10.5 6.68835 10.5 6.874V11.9C10.5 12.0857 10.4263 12.2637 10.295 12.395C10.1637 12.5263 9.98565 12.6 9.8 12.6H2.1C1.91435 12.6 1.7363 12.5263 1.60503 12.395C1.47375 12.2637 1.4 12.0857 1.4 11.9V4.2C1.4 4.01435 1.47375 3.8363 1.60503 3.70503C1.7363 3.57375 1.91435 3.5 2.1 3.5H7.126C7.31165 3.5 7.4897 3.42625 7.62097 3.29497C7.75225 3.1637 7.826 2.98565 7.826 2.8C7.826 2.61435 7.75225 2.4363 7.62097 2.30503C7.4897 2.17375 7.31165 2.1 7.126 2.1H2.1C1.54305 2.1 1.0089 2.32125 0.615076 2.71508C0.221249 3.1089 0 3.64305 0 4.2V11.9C0 12.457 0.221249 12.9911 0.615076 13.3849C1.0089 13.7788 1.54305 14 2.1 14H9.8C10.357 14 10.8911 13.7788 11.2849 13.3849C11.6788 12.9911 11.9 12.457 11.9 11.9V6.874C11.9 6.68835 11.8263 6.5103 11.695 6.37902C11.5637 6.24775 11.3857 6.174 11.2 6.174ZM13.944 0.434C13.873 0.262955 13.737 0.127032 13.566 0.0559999C13.4818 0.0201313 13.3915 0.00110599 13.3 0H9.1C8.91435 0 8.7363 0.0737498 8.60503 0.205025C8.47375 0.336301 8.4 0.514348 8.4 0.7C8.4 0.885652 8.47375 1.0637 8.60503 1.19497C8.7363 1.32625 8.91435 1.4 9.1 1.4H11.613L4.403 8.603C4.33739 8.66807 4.28531 8.74549 4.24978 8.8308C4.21424 8.9161 4.19594 9.00759 4.19594 9.1C4.19594 9.19241 4.21424 9.2839 4.24978 9.3692C4.28531 9.45451 4.33739 9.53193 4.403 9.597C4.46807 9.66261 4.54549 9.71469 4.6308 9.75022C4.7161 9.78576 4.80759 9.80406 4.9 9.80406C4.99241 9.80406 5.0839 9.78576 5.1692 9.75022C5.25451 9.71469 5.33193 9.66261 5.397 9.597L12.6 2.387V4.9C12.6 5.08565 12.6737 5.2637 12.805 5.39497C12.9363 5.52625 13.1143 5.6 13.3 5.6C13.4857 5.6 13.6637 5.52625 13.795 5.39497C13.9263 5.2637 14 5.08565 14 4.9V0.7C13.9989 0.608526 13.9799 0.518156 13.944 0.434V0.434Z" fill="black" />
                                    </svg>
                                </a>
                            </div>
                        }
                        <div className='text-black font-[20px] ml-[10px] font-bold'>
                            <div className='cursor-pointer leading-tight mt-[8px] mb-[12px]'>
                                {tender?.metadata?.customerName}
                            </div>
                            {
                                tender?.metadata?.date ? <div className='mt-[8px]'>
                                    {t("Deadline")}{" "}
                                    {
                                        !tender?.metadata?.date || tender?.metadata?.date === "" || 
                                        !getRemainingDaysAndHours(tender?.metadata?.date)?.remainingDays || 
                                        !getRemainingDaysAndHours(tender?.metadata?.date)?.remainingHours ? 
                                        "-" : 
                                        `${getRemainingDaysAndHours(tender?.metadata?.date)?.remainingDays}d ${getRemainingDaysAndHours(tender?.metadata?.date)?.remainingHours}t`
                                    }
                                    <br />
                                    {t("Est. Value")}{" "}
                                    {tender?.metadata?.est && tender?.metadata?.est !== "N/A" ? tender?.metadata?.est : "-"}
                                </div> : <></>
                            }
                        </div>
                        <div className='flex justify-between pr-3'>
                            <StatusTagsDropdown setOpenedStatus={setOpenedStatus} setMovedState={setMovedState} handleSelectedTab={handleSelectedTab} setTabName={setTabName} handleSelectedFolder={handleSelectedFolder} setTenderList={setTenderList} title={tender?.title} Statue={tender?.status ? tender?.status : "New"} tender_id={tender_id}></StatusTagsDropdown>
                        </div>
                        <div className="p-2 font-light">
                            <div>
                                <Droppable droppableId={`accordion_droppable_` + index} >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {
                                                documents.length > 0 ? documents.map((document: any, i: any) => {
                                                    const isIInList = selectedDocumentList.some(item => item.document === document);
                                                    return <Draggable key={i} draggableId={`accordion_` + index + "_" + i} index={i}>
                                                        {(provided: any, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className='flex'
                                                            >
                                                                {
                                                                    useEffect(() => {
                                                                        if (openedDocument === document) {
                                                                            handleMetadata(tabName, tender_title)
                                                                        }
                                                                    }, [openedDocument, document, tabName, tender_title])
                                                                }

                                                                {
                                                                    isIInList ? <CheckedIcon style={{ marginRight: '4px', marginBottom: '4px', minWidth: '24px' }} onClick={() => unselectDocument(i, document)}></CheckedIcon> : selectedDocumentList.length > 0 ? <UncheckedIcon style={{ marginRight: '4px', marginBottom: '4px', minWidth: '24px' }} onClick={() => selectDocument(i, document)}></UncheckedIcon> : <UncheckedDocumentIcon style={{ marginRight: '4px', marginBottom: '4px', minWidth: '24px' }} onClick={() => selectDocument(i, document)}></UncheckedDocumentIcon>
                                                                }
                                                                <div className={`text-black text-[14px] text-left hover:font-bold transition-all duration-200 cursor-pointer ${openedDocument == document ? "font-semibold" : ""}`} onClick={() => handleDocumentClick(i, document)}>
                                                                    {document}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                }) : (
                                                    <Draggable key={index} draggableId={`accordion_` + index + "_" + 0} index={index}>
                                                        {(provided: any, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <p className="text-black cursor-pointer h-[1px]" >&nbsp;</p>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                            <div className="max-w-xl mt-2">
                                <label
                                    className={`relative flex flex-col items-center justify-center w-full transition-all duration-200 ease-in-out bg-white rounded-lg hover:border-2 hover:border-dashed hover:border-orange-500 cursor-pointer
                                        ${isDraggingFiles 
                                            ? 'h-40 border-2 border-dashed border-orange-500' 
                                            : 'h-10'
                                        }`}
                                    onDrop={(e) => { 
                                        e.preventDefault();
                                        setIsDraggingFiles(false);
                                        handleDrop(e);
                                    }}
                                    onDragOver={(e) => { 
                                        e.preventDefault();
                                        if (e.dataTransfer.types.includes('Files')) {
                                            setIsDraggingFiles(true);
                                        }
                                    }}
                                    onDragLeave={(e) => {
                                        e.preventDefault();
                                        const rect = e.currentTarget.getBoundingClientRect();
                                        const x = e.clientX;
                                        const y = e.clientY;
                                        
                                        if (
                                            x <= rect.left || 
                                            x >= rect.right || 
                                            y <= rect.top || 
                                            y >= rect.bottom
                                        ) {
                                            setIsDraggingFiles(false);
                                        }
                                    }}
                                    onDragEnter={(e) => { 
                                        e.preventDefault();
                                        if (e.dataTransfer.types.includes('Files')) {
                                            setIsDraggingFiles(true);
                                        }
                                    }}
                                >
                                    {isDraggingFiles ? (
                                        // Sentrert layout for drag-tilstand
                                        <div className="flex flex-col items-center justify-center w-full h-full">
                                            <svg className="w-12 h-12 text-orange-500 mb-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  
                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  
                                                <polyline points="17 8 12 3 7 8" />  
                                                <line x1="12" y1="3" x2="12" y2="15" />
                                            </svg>
                                            <span className="text-sm text-gray-600">
                                                {t('add_files')}
                                            </span>
                                        </div>
                                    ) : (
                                        // Normal layout med ikon til venstre
                                        <div className="flex items-center justify-start h-full w-full group">
                                            <svg className="w-6 h-6 text-gray-400 group-hover:text-orange-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  
                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  
                                                <polyline points="17 8 12 3 7 8" />  
                                                <line x1="12" y1="3" x2="12" y2="15" />
                                            </svg>
                                            <div className="hidden group-hover:flex items-center w-full">
                                                <span className="ml-2 text-sm text-gray-600">
                                                    {t('add_files')}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <input type="file" name="file_upload" multiple={true} className="hidden" onChange={(e) => handleFileInputChange(e)} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div >
                <CreateDocumentModal 
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleSaveNewDocument={handleSaveNewDocument}
                    handleImportWebsite={handleImportWebsite}
                    isSaveStarted={isSaveStarted}
                />
            </div >
            {
                showDeleteFileModal ? (
                    <>
                        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                            <div className="relative w-[360px] my-6 mx-auto md:w-[30%]">
                                <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                        <h3 className="text-3xl font-semibold text-black">
                                            {t("Delete Confirmation Box")}
                                        </h3>
                                    </div>
                                    <div className="flex items-center justify-start p-6 border-t border-solid rounded-b border-slate-200">
                                        <h4 className='text-[black] text-[24px]'>
                                            {isFolderDelete 
                                                ? t("folder_delete_confirm_msg")
                                                : t("files_delete_confirm_msg")}
                                        </h4>
                                    </div>
                                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                        <button
                                            className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                            onClick={() => {
                                                setShowDeleteFileModal(false)
                                                setIsFolderDelete(false)
                                            }}
                                        >
                                            {t("Close")}
                                        </button>
                                        <button
                                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-700 rounded shadow outline-none active:bg-red-500 hover:shadow-lg focus:outline-none"
                                            onClick={() => {
                                                deleteFiles(tender, docList.filter(item => item?.tabName == tabName))
                                            }}
                                        >
                                            {t("Delete")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                    </>
                ) : null}
        </>
    )
}
