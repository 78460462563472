import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { parseJWT } from '../../../utils';
import { toast } from "react-toastify";
import { ReactComponent as Icon2 } from "../../../assets/svg/insight_refresh.svg";
import { ReactComponent as Icon3 } from "../../../assets/svg/complete.svg";
const { default: axios } = require('axios');

interface UploadFilesModalProps {
    tenderID: string;
    tenderList: any[];
    setModalOpen: (open: boolean) => void;
    addDocuments: (newDocs: any) => void;
    setTenderList: (list: any[]) => void;
    setOpenedStatus: (status: string) => void;
    handleSelectedFolder: (id: string, title: string) => void;
    deleteDocumentforSubmission: () => void;
    setTabName: (name: string) => void;
    requirement: string;
}

export const UploadFilesModal: React.FC<UploadFilesModalProps> = ({ 
    tenderID, 
    tenderList, 
    setModalOpen, 
    addDocuments, 
    setTenderList, 
    setOpenedStatus, 
    handleSelectedFolder, 
    deleteDocumentforSubmission, 
    setTabName, 
    requirement 
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<string>("tab_1");
    const [files, setFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const user = parseJWT(localStorage.getItem("tender_auth"));

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFiles(Array.from(event.target.files));
        }
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        if (e.dataTransfer.files) {
            setFiles(Array.from(e.dataTransfer.files));
        }
    };

    const uploadFiles = async () => {
        const formData = new FormData();
        formData.append("email", user.sub.email);
        formData.append('tab', selectedTab);
        formData.append("id", tenderID);
        formData.append('folder', tenderList.find(item => item.id === tenderID)?.title || '');
        formData.append('documentForSubmission', 'true');
        formData.append('description', requirement);
        
        files.forEach((file) => {
            formData.append('file', file);
        });

        try {
            deleteDocumentforSubmission();
            const toastId = toast(
                <div className='flex items-center p-1'>
                    <Icon2 className='animate-spin' />
                    {t("Uploading Document Files")}
                </div>,
                {
                    autoClose: false,
                    closeOnClick: true,
                    draggable: false,
                    hideProgressBar: true,
                }
            );

            const response = await axios.post(
                process.env.REACT_APP_API_URL + "api/uploadDocument", 
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                }
            );

            toast.update(toastId, {
                render: <div className='flex items-center p-1'>
                    <Icon3 />
                    {t("Document Files Uploaded")}
                </div>
            });

            setTimeout(async () => {
                toast.dismiss(toastId);
                const result = await axios.post(
                    process.env.REACT_APP_API_URL + "api/getTenders",
                    { companyId: user.sub.companyId },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                            "Content-Type": "application/json",
                        }
                    }
                );

                const activeTenders = result.data.data.filter(item => item.isFolderDeleted === 0);
                setTenderList(result.data.data);
                handleSelectedFolder(
                    tenderID, 
                    activeTenders[activeTenders.length > 0 ? activeTenders.length - 1 : 0]?.title
                );
                addDocuments(result.data.data);
            }, 2000);
        } catch (error) {
            console.error('Error uploading files:', error);
            toast.error(t("Error uploading files"));
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 z-[100]">
            <div className="flex flex-col w-full max-w-2xl bg-white rounded-lg shadow-xl">
                {/* Modal Header */}
                <div className="flex items-center p-6 border-b border-gray-200">
                    <img 
                        src={process.env.PUBLIC_URL + '/img/Filter_page1.png'} 
                        width={40} 
                        height={40} 
                        className='mr-4'
                        alt="Icon"
                    />
                    <h2 className="text-2xl font-semibold text-black">
                        {t("Upload Document")}
                    </h2>
                </div>

                {/* Modal Body */}
                <div className="p-6">
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-700">
                            {t("Save Location")}
                        </label>
                        <div className="flex flex-col gap-3">
                            <button
                                onClick={() => setSelectedTab("tab_2")}
                                className={`p-4 text-left border rounded-lg transition-colors ${
                                    selectedTab === "tab_2" ? 'border-cyan-500 bg-cyan-50' : 'border-gray-300 hover:border-cyan-500'
                                }`}
                            >
                                <div className="font-medium">{t("Save to Company Knowledge base")}</div>
                                <div className="text-sm text-gray-500">
                                    {t("Use this option when the document can be used for other tenders later")}
                                </div>
                            </button>
                            <button
                                onClick={() => setSelectedTab("tab_1")}
                                className={`p-4 text-left border rounded-lg transition-colors ${
                                    selectedTab === "tab_1" ? 'border-cyan-500 bg-cyan-50' : 'border-gray-300 hover:border-cyan-500'
                                }`}
                            >
                                <div className="font-medium">{t("Save to Tender in Workspace")}</div>
                                <div className="text-sm text-gray-500">
                                    {t("Use this option when the document is only for use for this tender")}
                                </div>
                            </button>
                        </div>
                    </div>

                    <div 
                        className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center hover:border-cyan-500 transition-colors"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="hidden"
                            ref={fileInputRef}
                            multiple
                        />
                        <div className="flex flex-col items-center gap-4">
                            <svg className="w-12 h-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" 
                                />
                            </svg>
                            <div>
                                <p className="text-gray-600">{t("Drag and drop files here")}</p>
                                <p className="text-gray-500 text-sm">{t("or")}</p>
                                <button 
                                    onClick={() => fileInputRef.current?.click()}
                                    className="text-cyan-600 hover:text-cyan-700 font-medium"
                                >
                                    {t("Choose from Computer")}
                                </button>
                            </div>
                        </div>
                    </div>

                    {files.length > 0 && (
                        <div className="mt-4">
                            <h3 className="mb-2 text-sm font-medium text-gray-700">{t("Selected Files")}</h3>
                            <ul className="text-sm text-gray-600">
                                {files.map((file, index) => (
                                    <li key={index} className="py-1">{file.name}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                {/* Modal Footer */}
                <div className="flex justify-end gap-3 p-6 bg-gray-50 rounded-b-lg">
                    <button
                        onClick={() => setModalOpen(false)}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={uploadFiles}
                        disabled={files.length === 0}
                        className={`px-4 py-2 text-sm font-medium text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 ${
                            files.length > 0 ? 'bg-cyan-700 hover:bg-cyan-800' : 'bg-gray-400 cursor-not-allowed'
                        }`}
                    >
                        {t("Upload")}
                    </button>
                </div>
            </div>
        </div>
    );
};