import React from 'react';
import { useTranslation } from 'react-i18next';
const ErrorPage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-center items-center h-screen bg-white-600">
            <div id="error-page">
                <h1 className="lg:text-6xl font-bold text-2xl text-black">{t("runtime_error")}</h1>
                <p className="text-xl text-black">
                    {t("runtime_error_message")}
                </p>
            </div>
        </div>
    )
};

export default ErrorPage;
