import React from 'react';

interface SummaryCardProps {
  summary: {
    title: string;
    document: string;
    summary: string;
  };
  handleDocumentClick: (document: string) => void;
  createMarkup: (text: string) => { __html: string };
}

export const SummaryCard: React.FC<SummaryCardProps> = ({
  summary,
  handleDocumentClick,
  createMarkup
}) => {
  const contentLength = summary.summary?.length || 0;
  const widthClass = contentLength > 300 ? 'w-[calc(98.2%)]' : 'w-[calc(47%)]';

  return (
    <div className={`relative box-sizing border-box bg-main_bg shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[10px] ${widthClass} mb-[10px] ml-[24px]`}>
      <div className='flex flex-col text-black'>
        <div className='flex py-[10px] px-[25px]' style={{ 'wordBreak': 'break-word' }}>
          <span className='text-[20px] font-bold text-left text-black'>
            {summary.title !== "" ? summary.title : summary.document}
          </span>
        </div>
        <div className='my-[20px] flex justify-center px-[25px] safari-scroll scrollbar-hide'>
          <div className='w-full'>
            <div 
              className='max-w-[100%] text-left prose text-xl text-black overflow-x-scroll scrollbar-hide'
              dangerouslySetInnerHTML={createMarkup(summary.summary.replace("```html", '').replace("```", "").trim())}
            />
          </div>
        </div>
        <div 
          className='px-[25px] pb-[10px] flex text-left text-black underline break-words cursor-pointer' 
          style={{ 'wordBreak': 'break-word' }} 
          onClick={() => handleDocumentClick(summary.document)}
        >
          {summary.document}
        </div>
      </div>
    </div>
  );
}; 