import React, { useState, useRef, useEffect } from 'react'
import "../../../App.css"
import { useTranslation } from 'react-i18next';
import { parseJWT } from '../../../utils';
import { toast } from 'react-toastify';
import { InsightModal } from '../../../Components/Shared/InsightModal';
import { PromptCard, Prompt } from '../../../Components/Shared/PromptCard';
import { ReactComponent as InsightRefreshIcon } from "../../../assets/svg/insight_refresh.svg";
import axios from 'axios';

interface CompareProps {
    openedDocument: string;
    promptList: Prompt[];
    selectedDocumentList: any[];
    handleEditPrompt: (prompt: Prompt) => void;
    selectedFolderID: string;
    promptDataFetch: () => Promise<void>;
    handleDocumentClick: (document: string) => void;
    isFromModal: boolean;
    setIsFromModal: (value: boolean) => void;
    tenderList: any[];
    setTenderList: (list: any[]) => void;
    setOpenedStatus: (status: string) => void;
    handleSelectedFolder: (folder: string) => void;
    selectedTab: string;
    setTabName: (name: string) => void;
    setCurrentSlide: (slide: number) => void;
    setAutoFilling: (value: boolean) => void;
    socketId: string;
    companyKnowledgeBaseId: string;
}

export const Compare: React.FC<CompareProps> = ({
    setCurrentSlide,
    promptList = [],
    selectedFolderID = "",
    selectedDocumentList = [],
    promptDataFetch,
    tenderList = [],
    openedDocument = "",
    handleEditPrompt = () => { },
    handleDocumentClick,
    isFromModal,
    setIsFromModal,
    setOpenedStatus,
    handleSelectedFolder,
    selectedTab,
    setTabName,
    setAutoFilling,
    socketId,
    companyKnowledgeBaseId
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState<'Creation' | 'Edition'>('Creation');
    const [initialData, setInitialData] = useState<any>(null);
    const [compareStates, setCompareStates] = useState<Map<string, boolean>>(() => {
        const map = new Map<string, boolean>();
        try {
            const storedStates = localStorage.getItem('compareStates');
            if (storedStates) {
                const parsed = JSON.parse(storedStates);
                Object.entries(parsed).forEach(([key, value]) => {
                    map.set(key, value as boolean);
                });
            }
        } catch (e) {
            console.error('Error loading compare states:', e);
        }
        return map;
    });
    let user = parseJWT(localStorage.getItem("tender_auth"));
    const [comparePromptList, setPromptList] = useState<Prompt[]>([]);

    useEffect(() => {
        if (promptList) {
            setPromptList(promptList);
        }
    }, [promptList]);

    const handlePlusButtonClick = () => {
        setShowModal(true);
        setModalAction('Creation');
        setInitialData({
            type: 'compare',
            isGlobalInsight: "No",
            tender_id: selectedFolderID,
            title: '',
            text: '',
            id: ''
        });
    };

    const handleModalClose = () => {
        setShowModal(false);
        setInitialData(null);
    };

    const handleModalSave = async () => {
        await promptDataFetch();
        setInitialData(null);
    };

    const handleEditClick = (prompt: any) => {
        const promptIndex = comparePromptList.findIndex(p => p.id === prompt.id);

        setShowModal(true);
        setModalAction('Edition');
        setInitialData({
            id: prompt.id,
            title: prompt.title,
            text: prompt.text,
            type: prompt.type,
            isGlobalInsight: prompt.isGlobalInsight,
            tender_id: prompt.tender_id,
            index: promptIndex
        });
    };

    const getPromptAnswer = async (text: string, index: number, id: string, isGlobalInsight?: string) => {
        const user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
        if (!user) return;

        try {
            // Set loading at start
            const updatedList = [...comparePromptList];
            updatedList[index].isLoading = true;
            setPromptList(updatedList);

            const params = {
                email: user.sub.email,
                companyId: user.sub.companyId,
                question: text + "\n",
                selectedDocumentList: selectedDocumentList.map(item => item.document),
                promptId: id,
                selectedFolderID: selectedFolderID,
                tender_id: selectedFolderID,
                companyKnowledgeBaseId: companyKnowledgeBaseId,
                isGlobalInsight: isGlobalInsight || "No"
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL}api/getAnswer`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            });

            if (!response.body) {
                throw new Error('Response body is null');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let isStarted = false;

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunkValue = decoder.decode(value);

                // Create a new list for each update
                const currentList = [...comparePromptList];
                const currentPrompt = currentList[index];

                if (!currentPrompt) continue;

                // Initialize response array if needed
                if (!currentPrompt.response) {
                    currentPrompt.response = [];
                }

                if (!isStarted) {
                    // Reset the response for this folder
                    const existingIndex = currentPrompt.response.findIndex(
                        (item: any) => Object.keys(item)[0] === selectedFolderID
                    );

                    if (existingIndex !== -1) {
                        currentPrompt.response[existingIndex] = { [selectedFolderID]: '' };
                    } else {
                        currentPrompt.response.push({ [selectedFolderID]: '' });
                    }
                    isStarted = true;
                }

                const existingIndex = currentPrompt.response.findIndex(
                    (item: any) => Object.keys(item)[0] === selectedFolderID
                );

                const regex = /([^:]*)json_data:(\{.*?\})/g;
                const match = regex.exec(chunkValue);

                if (match && match.length >= 3) {
                    const beforeText = match[1];
                    if (beforeText && existingIndex !== -1) {
                        currentPrompt.response[existingIndex][selectedFolderID] =
                            (currentPrompt.response[existingIndex][selectedFolderID] || '') + beforeText;
                    }
                } else if (existingIndex !== -1) {
                    currentPrompt.response[existingIndex][selectedFolderID] =
                        (currentPrompt.response[existingIndex][selectedFolderID] || '') + chunkValue;
                }

                // Update state with new content
                setPromptList([...currentList]);
            }

            // Only set loading to false after all processing is complete
            const finalList = [...comparePromptList];
            finalList[index].isLoading = false;
            setPromptList([...finalList]);

        } catch (error) {
            const errorList = [...comparePromptList];
            errorList[index].isLoading = false;
            setPromptList([...errorList]);
            console.error('Error getting answer:', error);
            toast.error(t("Error getting answer"));
        }
    };

    const getCompareAnswers = async () => {
        for (let index = 0; index < comparePromptList.length; index++) {
            const prompt = comparePromptList[index];
            if (prompt.type === 'compare') {
                await getPromptAnswer(prompt.text, index, prompt.id, prompt?.isGlobalInsight);
            }
        }
    };

    return (
        <>
            <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-bg_body focus:outline-none">
                <div className='flex items-center font-medium text-black text-xl text-left ml-2 mt-[5px]'>
                    <img src={process.env.PUBLIC_URL + '/img/Compare_page3.png'} width={50} height={50} className='mr-[10px]' alt="Compare" />
                    <div>
                        <div className='flex'>
                            <div className="text-xl font-bold text-left text-black font-['Montserrat']">{t('Compare')}</div>
                            <button
                                className="h-7 ml-[10px] cursor-pointer relative group"
                                onClick={getCompareAnswers}
                            >
                                <InsightRefreshIcon className='text-white' />
                            </button>
                        </div>
                        <div className='text-[15px]'>
                            {t("Compare_Description")}
                        </div>
                    </div>
                </div>

                <div className='safari-scroll scrollbar-hide h-[300px] md:h-[calc(100vh-115px)]'>
                    <div className="p-6 pb-[0] text-black bg-gray-100">
                        <div className="flex items-center cursor-pointer" onClick={() => {
                            const currentState = compareStates.get(selectedFolderID);
                            const newStates = new Map(compareStates);
                            newStates.set(selectedFolderID, !currentState);
                            setCompareStates(newStates);
                            localStorage.setItem('compareStates', JSON.stringify(Object.fromEntries(newStates)));
                        }}>
                            <svg
                                className={`w-5 h-5 mr-2 transition-transform ${compareStates.get(selectedFolderID) ? 'transform rotate-90' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                            <div className="flex items-center w-full">
                                <h1 className="text-xl font-bold text-left">{t('Compare_Header')}</h1>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlePlusButtonClick();
                                    }}
                                    className="ml-2 p-2 hover:bg-gray-200 rounded-full transition-colors"
                                >
                                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    {compareStates.get(selectedFolderID) && (
                        <div className='flex flex-wrap gap-6 p-6 mt-[0px] pl-[24px]'>
                            {comparePromptList
                                .map((prompt, index) =>
                                    prompt.type === 'compare' ? (
                                        <PromptCard
                                            key={index}
                                            prompt={prompt}
                                            index={index}  // This is now the original index
                                            selectedFolderID={selectedFolderID}
                                            companyKnowledgeBaseId={companyKnowledgeBaseId}
                                            selectedDocumentList={selectedDocumentList}
                                            membership={user?.sub?.membership}
                                            onUpdate={promptDataFetch}
                                            onEdit={handleEditClick}
                                            setPromptList={setPromptList}
                                            promptList={comparePromptList}
                                            tenderList={tenderList}
                                        />
                                    ) : null
                                ).filter(Boolean)}
                        </div>
                    )}
                </div>
            </div>

            {showModal && (
                <InsightModal
                    isOpen={showModal}
                    onClose={handleModalClose}
                    onSave={handleModalSave}
                    modalAction={modalAction}
                    initialData={initialData}
                    selectedFolderID={selectedFolderID}
                    tenderList={tenderList}
                    openedDocument={openedDocument}
                    handleEditPrompt={handleEditPrompt}
                    handleDocumentClick={handleDocumentClick}
                    isFromModal={isFromModal}
                    setIsFromModal={setIsFromModal}
                    setOpenedStatus={setOpenedStatus}
                    handleSelectedFolder={handleSelectedFolder}
                    selectedTab={selectedTab}
                    setTabName={setTabName}
                    setAutoFilling={setAutoFilling}
                    socketId={socketId}
                    companyKnowledgeBaseId={companyKnowledgeBaseId}
                />
            )}
        </>
    );
} 