import { inArray } from 'jquery'
import React from 'react'
import { useTranslation } from 'react-i18next';

interface UserPageProps {
    userData: any,
    handleEditClick: any
    companyName: any
    ownerUserId: any
    handleDeleteClick: any
    licenseCompany: any
    status: any
}
export const UserPage: React.FC<UserPageProps> = ({ userData, handleEditClick, companyName, ownerUserId, handleDeleteClick, licenseCompany, status }) => {
    const { t } = useTranslation();
    
    return (
        "email" in userData[0] && userData?.map((user, index: number) => {
            return <tr className="bg-white hover:bg-gray-50 transition-colors" key={index}>
                <td className="px-8 py-4 font-medium text-gray-900">
                    {user?.name}
                </td>
                <td className="px-8 py-4 text-gray-500">
                    {user?.email}
                </td>
                <td className="px-8 py-4 text-gray-500">
                    {user?.role ? user?.role : "Member"}
                </td>
                <td className="px-8 py-4">
                    {user?.isVerified ? 
                        <span className="px-2 py-1 text-sm rounded-full bg-green-50 text-green-700">Verified</span> : 
                        <span className="px-2 py-1 text-sm rounded-full bg-yellow-50 text-yellow-700">Pending</span>
                    }
                </td>
                {status == "deleted" ? 
                    <td className='px-8 py-4 text-right'>
                        <span className="px-2 py-1 text-sm rounded-full bg-red-50 text-red-700">{t("Disable")}</span>
                    </td> 
                    : 
                    <td className="px-8 py-4 text-right">
                        <div className="flex justify-end gap-3">
                            <button className="px-3 py-1.5 rounded-md font-medium text-blue-600 hover:bg-blue-50" 
                                onClick={() => handleEditClick(user, companyName, ownerUserId)}>
                                {t("Edit")}
                            </button>
                            <button className="px-3 py-1.5 rounded-md font-medium text-red-600 hover:bg-red-50" 
                                onClick={() => handleDeleteClick(user)}>
                                {t("Delete")}
                            </button>
                        </div>
                    </td>
                }
            </tr>
        })
    )
}